import axios from "axios";
import LocalApi from "./LocalApi";
import { squidUrl } from "./Environment";

class ConfigApi {
  constructor() {
    this.baseurl = `${squidUrl}/api`;
    this.errorCallback = undefined;
  }

  mountConfig = () => {
    const token = LocalApi.getToken();
    if (token) {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return {};
  };

  setErrorCallback = (errorCallback) => {
    this.errorCallback = errorCallback;
  };

  processError = (err) => {
    if (this.errorCallback) {
      this.errorCallback(err);
      this.errorCallback = undefined;
    }
  };

  //  ------ Methods to interact with API ------

  // General Config Information

  lisApplications = async (setCallback) => {
    await axios
      .get(`${this.baseurl}/auth/apps/`, this.mountConfig())
      .then((response) => {
        if (response.status === 200) {
          setCallback(response.data);
        }
      })
      .catch(this.processError);
  };

  lisApplicationsManaged = async (setCallback) => {
    await axios
      .get(`${this.baseurl}/auth/apps?subset=MANAGED`, this.mountConfig())
      .then((response) => {
        if (response.status === 200) {
          setCallback(response.data);
        }
      })
      .catch(this.processError);
  };

  getAppInformation = async (appId, setCallback) => {
    await axios
      .get(`${this.baseurl}/auth/apps/${appId}/`, this.mountConfig())
      .then((response) => {
        if (response.status === 200) {
          setCallback(response.data);
        }
      })
      .catch(this.processError);
  };

  // Management

  getAppManagers = async (appId, setCallback) => {
    await axios
      .get(`${this.baseurl}/auth/apps/${appId}/managers`, this.mountConfig())
      .then((response) => {
        if (response.status === 200) {
          setCallback(response.data);
        }
      })
      .catch(this.processError);
  };

  addManager = async (appId, userKey, successCb, failCb) => {
    await axios
      .post(
        `${this.baseurl}/auth/apps/${appId}/managers?petrobras-key=${userKey}`,
        {},
        this.mountConfig()
      )
      .then((response) => {
        if (response.status === 200) {
          successCb();
        }
      })
      .catch(failCb || this.processError);
  };

  removeManager = async (appId, userKey, successCb) => {
    await axios
      .delete(
        `${this.baseurl}/auth/apps/${appId}/managers?petrobras-key=${userKey}`,
        this.mountConfig()
      )
      .then((response) => {
        if (response.status === 200) {
          successCb();
        }
      })
      .catch(this.processError);
  };

  // Roles

  getAppRoles = async (appId, setCallback) => {
    await axios
      .get(`${this.baseurl}/auth/apps/${appId}/roles`, this.mountConfig())
      .then((response) => {
        if (response.status === 200) {
          setCallback(response.data);
        }
      })
      .catch(this.processError);
  };

  getAppAndUserInformationRoles = async (appId, setCallback) => {
    await axios
      .get(
        `${this.baseurl}/auth/apps/${appId}/roles/by-user`,
        this.mountConfig()
      )
      .then((response) => {
        if (response.status === 200) {
          setCallback(response.data);
        }
      })
      .catch(this.processError);
  };

  createApplicationRole = async (appId, payload, successCb) => {
    await axios
      .post(
        `${this.baseurl}/auth/apps/${appId}/roles`,
        payload,
        this.mountConfig()
      )
      .then((response) => {
        if (response.status === 200) {
          successCb();
        }
      })
      .catch(this.processError);
  };

  removeRole = async (appId, roleId, failCb) => {
    await axios
      .delete(
        `${this.baseurl}/auth/apps/${appId}/roles?id=${roleId}`,
        this.mountConfig()
      )
      .catch(failCb || this.processError);
  };

  revokeUserRole = async (appId, payload, failCb) => {
    await axios
      .post(
        `${this.baseurl}/auth/apps/${appId}/roles/revoke`,
        payload,
        this.mountConfig()
      )
      .catch(failCb || this.processError);
  };

  grantUserRole = async (appId, payload, successCb, failCb) => {
    await axios
      .post(
        `${this.baseurl}/auth/apps/${appId}/roles/grant`,
        payload,
        this.mountConfig()
      )
      .then((response) => {
        if (response.status === 200) {
          successCb();
        }
      })
      .catch(failCb || this.processError);
  };

  listUsersByRole = async (roleId, setCallback) => {
    await axios
      .get(
        `${this.baseurl}/auth/roles/users?role-id=${roleId}`,
        this.mountConfig()
      )
      .then((response) => {
        if (response.status === 200) {
          setCallback(response.data);
        }
      })
      .catch(this.processError);
  };

  // Apps

  authorizeApp = async (appId, appToAttachId, failCb) => {
    await axios
      .post(
        `${this.baseurl}/auth/apps/${appId}/authorized-apps?consumer-app-id=${appToAttachId}`,
        {},
        this.mountConfig()
      )
      .catch(failCb || this.processError);
  };

  getAuthorizedAppsList = async (appId, setCallback) => {
    await axios
      .get(
        `${this.baseurl}/auth/apps/${appId}/authorized-apps`,
        this.mountConfig()
      )
      .then((response) => {
        if (response.status === 200) {
          setCallback(response.data);
        }
      })
      .catch(this.processError);
  };

  detachAuthorizedApp = async (appId, appToAttachId, failCb) => {
    await axios
      .delete(
        `${this.baseurl}/auth/apps/${appId}/authorized-apps?consumer-app-id=${appToAttachId}`,
        this.mountConfig()
      )
      .catch(failCb || this.processError);
  };

  // Token

  renewToken = async (appId, setCallback) => {
    await axios
      .post(
        `${this.baseurl}/auth/logon/app/${appId}/renew-token`,
        {},
        this.mountConfig()
      )
      .then((response) => {
        if (response.status === 200) {
          setCallback(response.data);
        }
      })
      .catch(this.processError);
  };

  // Users

  listApplicationRolesOfUser = async (setCallback) => {
    await axios
      .get(`${this.baseurl}/auth/users/roles`, this.mountConfig())
      .then((response) => {
        if (response.status === 200) {
          setCallback(response.data);
        }
      })
      .catch(this.processError);
  };

  getHealthCheckApps = async (setCallback, setLoading) => {
    if (setLoading) setLoading(true);
    return await axios({
      method: "get",
      url: `${this.baseurl}/healthcheck/applications?token`,
      headers: {
        Authorization: `Bearer ${LocalApi.getToken()}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return setCallback(response.data);
        }
      })
      .catch(this.processError)
      .finally(() => {
        if (setLoading) setLoading(false);
      });
  };
}

const configApi = new ConfigApi();

export default configApi;
