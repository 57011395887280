import React from "react";
import PropTypes from "prop-types";
import Color from "color";

import { Container } from "squid-ui-kit";

import TopBar from "../TopBar";
import HelpWindow from "./components/HelpWindow";

class ApplicationPresentation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let help;
    if (this.props.showHelp && this.props.showHelp === true) {
      help = (
        <HelpWindow
          tokens={this.props.tokens}
          onClose={() => {
            if (this.props.onCloseHelp) {
              this.props.onCloseHelp();
            }
          }}
        />
      );
    }

    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: Color(this.props.tokens.ColorPrimary).alpha(0.9),
        }}
      >
        <Container
          width="100%"
          height="100%"
          direction="vertical"
          bodyType="neutral"
          padding="0px"
        >
          <TopBar
            tokens={this.props.tokens}
            tabs={this.props.tabs}
            tab={this.props.tab}
            onTabChange={this.props.onTabChange}
            onTabClose={this.props.onTabClose}
            onHealthCheck={this.props.onHealthCheck}
            onHelp={this.props.onHelp}
            onSetup={this.props.onSetup}
            onLogout={this.props.onLogout}
            isAdmin={this.props.isAdmin}
          />
          <Container
            width="100%"
            flex="1"
            direction="vertical"
            bodyType="neutral"
            padding="0px"
          >
            {this.props.children}
          </Container>
        </Container>
        {help}
      </div>
    );
  }
}

ApplicationPresentation.propTypes = {
  isAdmin: PropTypes.bool,
  tabs: PropTypes.arrayOf(PropTypes.object),
  tab: PropTypes.string,
  onTabChange: PropTypes.func,
  onTabClose: PropTypes.func,
  onHelp: PropTypes.func,
  onHealthCheck: PropTypes.func,
  onSetup: PropTypes.func,
  onLogout: PropTypes.func,
  showHelp: PropTypes.bool,
  onCloseHelp: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

ApplicationPresentation.defaultProps = {
  isAdmin: false,
  tabs: [],
  tab: "",
  src: null,
};

export default ApplicationPresentation;
