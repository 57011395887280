import React from "react";
import PropTypes from "prop-types";
import Color from "color";

import { Container } from "squid-ui-kit";

class PostCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let image;
    if (this.props.image) {
      console.log("+++++++++++ IMAGE +++++++++++", this.props.image);
      image = (
        <Container
          width="100%"
          height="auto"
          direction="horizontal"
          verticalAlign="center"
          bodyType="content"
        >
          <img
            alt=""
            style={{
              width: "100%",
              height: "auto",
            }}
            src={this.props.image}
          />
        </Container>
      );
    }

    return (
      <div
        style={{
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          marginBottom: "10px",
          backgroundColor: Color(this.props.tokens.ColorWhite).alpha(0.9),
        }}
      >
        <Container
          width="100%"
          height="24px"
          direction="horizontal"
          verticalAlign="center"
          bodyType="neutral"
          padding="10px"
        >
          <div
            style={{
              flex: 1,
              fontFamily: this.props.tokens.FontTitlePrimaryFamily,
              fontWeight: this.props.tokens.FontTitlePrimaryWeight,
              fontSize: this.props.tokens.FontTitlePrimarySize,
              lineHeight: this.props.tokens.LineHeightBodyAction,
              color: this.props.tokens.ColorPrimaryDark,
            }}
          >
            {this.props.author}
          </div>
          <div
            style={{
              width: "auto",
              fontFamily: this.props.tokens.FontTitlePrimaryFamily,
              fontWeight: this.props.tokens.FontTitlePrimaryWeight,
              fontSize: this.props.tokens.FontTitlePrimarySize,
              lineHeight: this.props.tokens.LineHeightBodyAction,
              color: this.props.tokens.ColorPrimaryDark,
            }}
          >
            {this.props.data}
          </div>
        </Container>
        {image}
        <div
          style={{
            width: "100%",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            padding: "0px",
            fontFamily: this.props.tokens.FontBodySecondaryFamily,
            fontWeight: this.props.tokens.FontBodySecondaryWeight,
            fontSize: this.props.tokens.FontBodySecondarySize,
            lineHeight: this.props.tokens.LineHeightBodyAction,
            color: this.props.tokens.ColorPrimaryDark,
            backgroundColor: this.props.tokens.ColorWhite,
          }}
        >
          <span style={{ margin: "10px" }}>{this.props.body}</span>
        </div>
      </div>
    );
  }
}

PostCard.propTypes = {
  author: PropTypes.string,
  data: PropTypes.string,
  body: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  image: PropTypes.string,
};

PostCard.defaultProps = {
  author: "",
  data: "",
  body: "",
  image: "",
};

export default PostCard;
