import React from "react";
import PropTypes from "prop-types";

import {
  Table,
  Panel,
  Text,
  Spacer,
} from "squid-ui-kit";
import Container from "squid-ui-kit/dist/Container";

class ProfilesPresentation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    if (this.props.columns && this.props.columns.length > 0) {
      return (
        <Container width="100%" height="150px" vertical="horizontal" bodyType="neutral">
          <Container height="25px" width="100%" bodyType="active" direction="horizontal" verticalAlign="center" padding="10px">
            <Text bold> PERFIS DE USUÁRIO </Text>
            <Spacer/>
          </Container>
          <Table
            width="100%"
            height="100%"
            dataSource={this.props.profiles}
            columns={this.props.columns}
            // rowHeight="32px"
            // addButton={true}
            // onAdd={this.props.onAdd}
          />
        </Container>
      );
    } else {
      return (
        <Panel
          label="Perfis de Usuário"
          width="100%"
          height="150px"
          direction="vertical"
          verticalAlign="top"
          bodyType="primary"
        ></Panel>
      );
    }
  }
}

ProfilesPresentation.propTypes = {
  profiles: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.object),
  onAdd: PropTypes.func,
};

ProfilesPresentation.defaultProps = {
  profiles: [],
  columns: [],
};

export default ProfilesPresentation;
