import React from "react";
import PropTypes from "prop-types";
import Color from "color";

import {
  Alert,
  Button,
  Container,
  Dropdown,
  Icon,
  IconButton,
  InfoItem,
  ListItem,
  Panel,
  Spacer,
  TextArea,
  TextField,
  tokens,
  Tooltip,
} from "squid-ui-kit";
import Divider from "squid-ui-kit/dist/Divider";

import AppButton from "../../AppButton";

class WindowFormApplication extends React.Component {
  constructor(props) {
    super(props);

    this.inputIconRef = React.createRef();

    this.state = {
      id: this.props.application?.id,
      name: this.props.application?.name ?? "",
      description: this.props.application?.description ?? "",
      url: this.props.application?.url ?? "",
      urlPlaceholder: "Exemplo: /app/",
      typeId: this.props.application?.type.id ?? "2",
      icon: this.props.application?.icon,
      launchModeCode:
        this.props.application?.launchMode.code ?? "INSIDE_PORTAL",
      errorIcon: undefined,
      errorName: undefined,
      errorDescription: undefined,
      errorTypeId: undefined,
      errorUrl: undefined,
      errorLaunchModeCode: undefined,
      errorAlertMessage: undefined,
      slug: this.props.application?.slug,
      slugPlaceholder:
        "Identificador único da aplicação na plataforma (em todos os portais)",
    };

    this.supportedIconFormats = ".png, .jpg, .jpeg";

    this.hasApp = () => {
      return this.props.application;
    };

    this.onNameChange = (e) => {
      this.setState(
        {
          name: e.value,
        },
        this.updateUrlPlaceholder
      );
    };
    this.onDescriptionsChange = (e) => {
      this.setState({
        description: e.value,
      });
    };
    this.onTypeIdChange = (e) => {
      this.setState(
        {
          typeId: e.value,
        },
        this.updateUrlPlaceholder
      );
    };
    this.onUrlChange = (e) => {
      this.setState({
        url: e.value,
      });
    };
    this.onSlugChange = (e) => {
      this.setState({
        slug: e.value,
      });
    };
    // Regex para substituir whitespace (" ") de forma global
    const regex = /\s/g;
    this.updateUrlPlaceholder = () => {
      this.setState({
        urlPlaceholder: `Exemplo: /${
          this.state.typeId === "1" ? "basicdata" : "app"
        }/${this.state.name.toLowerCase().replace(regex, "-")}/`,
      });
    };
    this.onIconAdd = (e) => {
      this.inputIconRef.current.click();
    };
    this.onIconRemove = () => {
      this.inputIconRef.current.value = "";
      this.setState({
        icon: null,
      });
    };
    this.onIconChange = (e) => {
      const file = e.target?.files[0];
      if (!file) {
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        this.setState({
          icon: content,
        });
      };
      reader.readAsDataURL(file);
    };
    this.onLaunchModeCodeChange = (e) => {
      this.setState({
        launchModeCode: e.value,
      });
    };
    this.onRemove = () => {
      this.props.onRemove(this.props.application);
    };

    this.validate = () => {
      let errorIcon;
      let errorName;
      let errorDescription;
      let errorTypeId;
      let errorUrl;
      let errorLaunchModeCode;

      if (!this.state.icon) {
        errorIcon = "É necessário escolher um ícone";
      }
      if (!this.state.name || this.state.name === "") {
        errorName = "É necessário informar o nome";
      }
      if (!this.state.description || this.state.description === "") {
        errorDescription = "É necessário informar a descrição";
      }
      if (!this.state.typeId || this.state.typeId === "") {
        errorTypeId = "É necessário selecionar um tipo de aplicação";
      }
      if (!this.state.url || this.state.url === "") {
        errorUrl = "É necessário informar a URL local";
      }
      if (!this.state.launchModeCode || this.state.launchModeCode === "") {
        errorLaunchModeCode =
          "É necessário selecionar a forma de lançamento no portal";
      }

      this.setState({
        errorIcon,
        errorName,
        errorDescription,
        errorTypeId,
        errorUrl,
        errorLaunchModeCode,
      });

      const hasError =
        errorIcon ||
        errorName ||
        errorDescription ||
        errorTypeId ||
        errorUrl ||
        errorLaunchModeCode;

      return !hasError;
    };

    this.onCreate = (e) => {
      if (!this.validate()) {
        return;
      }

      this.props
        .onCreate({
          icon: this.state.icon,
          name: this.state.name,
          description: this.state.description,
          typeId: this.state.typeId,
          url: this.state.url,
          launchModeCode: this.state.launchModeCode,
          slug: this.state.slug,
        })
        .catch((err) => {
          this.setState({
            errorAlertMessage: err.message,
          });
        });
    };

    this.onUpdate = () => {
      if (!this.validate()) {
        return;
      }

      this.props
        .onUpdate({
          id: this.state.id,
          icon: this.state.icon,
          name: this.state.name,
          description: this.state.description,
          typeId: this.state.typeId,
          url: this.state.url,
          launchModeCode: this.state.launchModeCode,
          slug: this.state.slug,
        })
        .catch((err) => {
          this.setState({
            errorAlertMessage: err.message,
          });
        });
    };
  }

  render() {
    let errorAlert;
    if (this.state.errorAlertMessage) {
      errorAlert = (
        <Alert
          hasBorder={true}
          bodyType="content"
          padding={tokens.PrimaryPadding}
          title={
            "Erro ao " +
            (this.hasApp() ? "atualizar" : "cadastrar") +
            " aplicação"
          }
          message={this.state.errorAlertMessage}
          onClose={() => this.setState({ errorAlertMessage: undefined })}
          confirmLabel="OK"
        />
      );
    }
    let footer = (
      <Container
        width="auto"
        bodyType="neutral"
        padding="0px"
        direction="horizontal"
        horizontalAlign="right"
      >
        {this.hasApp() ? (
          <Button label="REMOVER" type="tertiary" onClick={this.onRemove} />
        ) : undefined}
        <Spacer flex="1" />
        <Button
          label={!this.hasApp() ? "CRIAR" : "SALVAR"}
          type="cta"
          onClick={!this.hasApp() ? this.onCreate : this.onUpdate}
        />
      </Container>
    );
    return (
      <div
        style={{
          display: "flex",
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
          backgroundColor: Color(this.props.tokens.ColorBlack).alpha(0.3),
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-start",
          zIndex: 20,
        }}
      >
        {errorAlert}
        <Panel
          label={`${!this.hasApp() ? "Adicionar nova" : "Editar"} aplicação`}
          direction="vertical"
          verticalAlign="top"
          horizontalAlign="left"
          bodyType="primary"
          width="500px"
          height="100%"
          footer={footer}
          closeButton={true}
          padding="10px"
          space="10px"
          onClose={this.props.onClose}
        >
          <input
            id="inputIcon"
            ref={this.inputIconRef}
            type="file"
            style={{
              display: "none",
            }}
            onChange={this.onIconChange}
            accept={this.supportedIconFormats}
          />
          <Panel
            label="Ícone"
            width="100%"
            padding="20px"
            verticalAlign="center"
            horizontalAlign="center"
            direction="horizontal"
            scroll="unset"
          >
            <Container
              bodyType="neutral"
              verticalAlign="center"
              horizontalAlign="center"
              width="100%"
              flex="1"
            >
              {!this.state.icon ? (
                <Container
                  // bodyType="neutral"
                  verticalAlign="center"
                  horizontalAlign="center"
                  width="150px"
                  height="150px"
                >
                  <IconButton name="addIconButton" onClick={this.onIconAdd} />
                </Container>
              ) : (
                <>
                  <img
                    alt=""
                    src={this.state.icon}
                    style={{
                      width: "150px",
                      height: undefined,
                      aspectRatio: 1,
                    }}
                  />
                  <IconButton
                    icon="trash"
                    name="removeIconButton"
                    onClick={this.onIconRemove}
                  />
                </>
              )}
              {this.state.errorIcon ? (
                <Tooltip placement="bottom" title={this.state.errorIcon}>
                  <Icon icon="info" color="red" />
                </Tooltip>
              ) : undefined}
            </Container>
            <Divider direction="vertical" />
            <Container padding="15px" bodyType="neutral">
              <InfoItem
                label="Formatos suportados"
                value={this.supportedIconFormats}
              />
              <Spacer direction="vertical" size="20px" />
              <InfoItem label="Proporção ideal" value="1:1" />
              <Spacer direction="vertical" size="20px" />
              <InfoItem label="Tamanho mínimo" value="80x80 (px)" />
            </Container>
          </Panel>
          <Spacer size="10px" />
          <Divider />
          <TextField
            width="100%"
            label="Nome"
            value={this.state.name}
            error={this.state.errorName}
            onChange={this.onNameChange}
          />
          <TextArea
            width="100%"
            label="Descrição"
            value={this.state.description}
            error={this.state.errorDescription}
            onChange={this.onDescriptionsChange}
          />
          <Dropdown
            width="100%"
            label="Tipo"
            selectedKey={this.state.typeId.toString()}
            value={this.state.typeId.toString()}
            error={this.state.errorTypeId}
            onChange={this.onTypeIdChange}
          >
            <ListItem key="2" title="Aplicação" />
            <ListItem key="1" title="Dado básico" />
          </Dropdown>
          <TextField
            width="100%"
            label="URL"
            placeholder={this.state.urlPlaceholder}
            value={this.state.url}
            error={this.state.errorUrl}
            onChange={this.onUrlChange}
          />
          <Container bodyType="neutral" width="100%" direction="horizontal">
            <Dropdown
              flex="1"
              label="Modo de lançamento"
              selectedKey={this.state.launchModeCode}
              value={this.state.launchModeCode}
              error={this.state.errorLaunchModeCode}
              onChange={this.onLaunchModeCodeChange}
            >
              <ListItem key="INSIDE_PORTAL" title="Dentro do portal" />
              <ListItem key="OUTSIDE_PORTAL" title="Fora do portal" />
            </Dropdown>
          </Container>
          <TextField
            width="100%"
            label="Slug"
            placeholder={this.state.slugPlaceholder}
            value={this.state.slug}
            onChange={this.onSlugChange}
          />
          <Spacer direction="vertical" size="5px" />
          <Panel
            label="Preview"
            width="100%"
            padding="20px"
            verticalAlign="center"
            horizontalAlign="center"
          >
            <AppButton
              tokens={this.props.tokens}
              label={this.state.name}
              icon={this.state.icon}
            />
          </Panel>
        </Panel>
      </div>
    );
  }
}

WindowFormApplication.propTypes = {
  application: PropTypes.object,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
};

WindowFormApplication.defaultProps = {};

export default WindowFormApplication;
