import { ApolloClient, createHttpLink, gql, InMemoryCache, } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Icon } from "squid-ui-kit";
import LocalApi from "./LocalApi";
import { squidUrl } from "./Environment";
import healthCheckIcon from "../assets/health-check-icon.svg";

window.SQUID_URL = squidUrl;
window.SQUID_CORE_SERVICE_URL = `${squidUrl}/api/core/graphql`;

const httpLink = createHttpLink({
  uri: window.SQUID_CORE_SERVICE_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = LocalApi.getToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
  onError: ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );

    if (networkError) console.log(`[Network error]: ${networkError}`);
  },
});

const FETCH_PORTAL = gql`
  query FetchPortal($portalId: String!) {
    portals {
      single(id: $portalId) {
        id
        name
        url
      }
    }
  }
`;
const FETCH_ALL_APPS = gql`
  query listApps($portalId: String!) {
    applications {
      list(portalId: $portalId) {
        id
        name
        description
        url
        icon
        launchMode {
          code
        }
        type {
          id
          name
        }
        slug
      }
    }
  }
`;
const LIST_APPS = gql`
  query listApps($portalId: String!) {
    applications {
      list(portalId: $portalId, typeId: 2) {
        id
        name
        description
        url
        icon
        launchMode {
          code
        }
        type {
          id
          name
        }
        slug
      }
    }
  }
`;
const LIST_BASIC_DATA = gql`
  query listApps($portalId: String!) {
    applications {
      list(portalId: $portalId, typeId: 1) {
        id
        name
        description
        url
        icon
        launchMode {
          code
        }
        type {
          id
        }
        slug
      }
    }
  }
`;
// The 'visible' parameter is related to a user authorization feature present only in the SUBWEB Portal, but necessary for the request to be successful
const CREATE_APPLICATION = gql`
    mutation createApplication($portalId: String!, $name: String!, $description: String!, $typeId: Int, $url: String!, $icon: String!, $launchModeCode: String!, $slug: String) {
        applications {
            create(portalId: $portalId, application: {
                name: $name
                description: $description
                typeId: $typeId
                url: $url
                icon: $icon
                launchModeCode: $launchModeCode
                visible: true
                slug: $slug
            }) {
                id
                name
                description
                url
                icon
                slug
            }
        }
    }
`;
// The 'visible' parameter is related to a user authorization feature present only in the SUBWEB Portal, but necessary for the request to be successful
const UPDATE_APPLICATION = gql`
    mutation updateApplication($portalId: String!, $id: String!, $name: String!, $description: String!, $typeId: Int, $url: String!, $icon: String!, $launchModeCode: String!, $slug: String) {
        applications {
            update(portalId: $portalId, id: $id, application: {
                name: $name
                description: $description
                typeId: $typeId
                url: $url
                icon: $icon
                launchModeCode: $launchModeCode
                visible: true
                slug: $slug
            }) {
                id
                name
                description
                url
                icon
                slug
            }
        }
    }
`
const DELETE_APPLICATION = gql`
    mutation delete($portalId: String!, $id: String!) {
        applications {
            delete(portalId: $portalId, id: $id)
        }
    }
`
const LIST_GROUPS = gql`
  query fetchGroups($portalId: String!) {
    groups {
      list(portalId: $portalId) {
        id
        name
      }
    }
  }
`;
const LIST_USERS = gql`
  query listUsers($portalId: String!) {
    users {
      list(portalId: $portalId) {
        id
        name
        email
        avatar
        petrobrasKey
        groups {
          id
          name
        }
      }
    }
  }
`;
const FETCH_USER = gql`
  query fetchUser($petrobrasKey: String!) {
    users {
      singleByPetrobrasKey(petrobrasKey: $petrobrasKey) {
        petrobrasKey
        name
        email
      }
    }
  }
`;
const FETCH_ME = gql`
  query fetchMe {
    users {
      me {
        id
        name
        email
        avatar
        petrobrasKey
        groups {
          id
          name
        }
      }
    }
  }
`;
const ADD_USER = gql`
  mutation create($portalId: String!, $user: UserInput!) {
    users {
      create(portalId: $portalId, user: $user) {
        id
        petrobrasKey
        name
        email
        avatar
        groups {
          id
          name
        }
      }
    }
  }
`;
const EDIT_USER = gql`
  mutation updateUser(
    $portalId: String!
    $userId: String!
    $userInput: UserInput!
  ) {
    users {
      update(portalId: $portalId, id: $userId, user: $userInput) {
        id
        name
        email
        avatar
      }
    }
  }
`;
// const EDIT_USER = gql`
// mutation updateUser{
//     users{
//       update(portalId: "portal_navalweb", id: "854b02b2-3c35-4560-af94-c684564298c5", user: {
//         name: "Diogo Cintra"
//         email: "diogotc@lccv.ufal.br"
//         petrobrasKey: "CVWQ"
//       }){
//         id
//         name
//         email
//         avatar
//       }
//     }
//   }
// `;
const USER_JOIN_GROUP = gql`
  mutation joinGroup($portalId: String!, $groupId: String!, $id: String!) {
    users {
      joinGroup(portalId: $portalId, groupId: $groupId, id: $id)
    }
  }
`;
const USER_LEAVE_GROUP = gql`
  mutation leaveGroup($portalId: String!, $groupId: String!, $id: String!) {
    users {
      leaveGroup(portalId: $portalId, groupId: $groupId, id: $id)
    }
  }
`;
const DELETE_USER = gql`
  mutation deleteUser($portalId: String!, $id: String!) {
    users {
      delete(id: $id, portalId: $portalId)
    }
  }
`;
const AUTH = gql`
  mutation authenticate(
    $portalId: String!
    $login: String!
    $password: String!
  ) {
    authentication {
      login(portalId: $portalId, key: $login, password: $password) {
        token
      }
    }
  }
`;
const VALIDATE_TOKEN = gql`
  mutation ValidateToken($token: String!) {
    authentication {
      validate(token: $token)
    }
  }
`;

export default class CoreBackendApi {

  static fetchCurrentPortal() {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: FETCH_PORTAL,
          variables: { portalId: window.SQUID_PORTAL_ID },
        })
        .then((result) => {
          resolve(result.data.portals.single);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static fetchAllApps() {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: FETCH_ALL_APPS,
          variables: {
            portalId: window.SQUID_PORTAL_ID,
          },
        })
        .then((result) => {
          resolve(result.data.applications.list);
        })
        .catch(reject);
    });
  }

  static listApps() {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: LIST_APPS,
          variables: { portalId: window.SQUID_PORTAL_ID },
        })
        .then((result) => {
          let apps = [
            {
              index: "home",
              label: "Home",
              compact: true,
              launchMode: { code: "INSIDE_PORTAL" },
              src: "",
            },
            {
              index: "healthCheck",
              label: "Healthcheck",
              compact: false,
              launchMode: { code: "INSIDE_PORTAL" },
              icon: <img src={healthCheckIcon} alt={"health-check"} />,
              src: "",
            },
            {
              index: "setup",
              label: "Setup",
              compact: false,
              launchMode: { code: "INSIDE_PORTAL" },
              icon: <Icon icon="setup" size="large" />,
              src: "",
            },
          ];
          const squidApps = result.data.applications.list;
          for (let i in squidApps) {
            let app = {
              index: squidApps[i].id,
              id: squidApps[i].id,
              label: squidApps[i].name,
              name: squidApps[i].name,
              description: squidApps[i].description,
              compact: false,
              launchMode: squidApps[i].launchMode,
              src: squidApps[i].url,
              url: squidApps[i].url,
              icon: squidApps[i].icon,
              type: squidApps[i].type,
              slug: squidApps[i].slug,
            };
            apps.push(app);
          }
          resolve(apps);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static listBasicData() {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: LIST_BASIC_DATA,
          variables: { portalId: window.SQUID_PORTAL_ID },
        })
        .then((result) => {
          let apps = [];
          const squidApps = result.data.applications.list;
          for (let i in squidApps) {
            let app = {
              index: squidApps[i].id,
              label: squidApps[i].name,
              compact: false,
              launchMode: squidApps[i].launchMode,
              src: squidApps[i].url,
              icon: squidApps[i].icon,
              slug: squidApps[i].slug,
            };
            apps.push(app);
          }
          resolve(apps);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static createApplication(app) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: CREATE_APPLICATION,
          variables: {
            ...app,
            portalId: window.SQUID_PORTAL_ID,
          },
        })
        .then((result) => {
          resolve(result.data.applications.create);
        })
        .catch(reject);
    });
  }

  static updateApplication(app) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: UPDATE_APPLICATION,
          variables: {
            ...app,
            portalId: window.SQUID_PORTAL_ID,
          },
        })
        .then((result) => {
          resolve(result.data.applications.update)
        })
        .catch(reject);
    });
  }

  static deleteApplication(app) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: DELETE_APPLICATION,
          variables: {
            portalId: window.SQUID_PORTAL_ID,
            id: app.id,
          },
        })
        .then(resolve)
        .catch(reject);
    })
  }

  static listGroups() {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: LIST_GROUPS,
          variables: { portalId: window.SQUID_PORTAL_ID },
        })
        .then((result) => {
          resolve(result.data.groups.list);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static listUsers() {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: LIST_USERS,
          variables: { portalId: window.SQUID_PORTAL_ID },
          errorPolicy: "all",
        })
        .then((result) => {
          resolve(result.data.users.list);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static fetchUser(petrobrasKey, fail) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: FETCH_USER,
          variables: {
            portalId: window.SQUID_PORTAL_ID,
            petrobrasKey: petrobrasKey,
          },
        })
        .then((result) => {
          resolve(result.data.users.singleByPetrobrasKey);
        })
        .catch((error) => {
          fail(error);
          reject(error);
        });
    });
  }

  static fetchMe() {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: FETCH_ME,
        })
        .then((result) => {
          resolve(result.data.users.me);
        })
        .catch(reject);
    });
  }

  static addUser(user, fail) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: ADD_USER,
          variables: {
            portalId: window.SQUID_PORTAL_ID,
            user: user,
          },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          fail(error);
        });
    });
  }

  static editUser(userId, user) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: EDIT_USER,
          variables: {
            portalId: window.SQUID_PORTAL_ID,
            userId: userId,
            userInput: user,
          },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static leaveGroupUser(userId, groupId) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: USER_LEAVE_GROUP,
          variables: {
            portalId: window.SQUID_PORTAL_ID,
            id: userId,
            groupId: groupId,
          },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static joinGroupUser(userId, groupId) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: USER_JOIN_GROUP,
          variables: {
            portalId: window.SQUID_PORTAL_ID,
            id: userId,
            groupId: groupId,
          },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static deleteUser(userId) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: DELETE_USER,
          variables: {
            portalId: window.SQUID_PORTAL_ID,
            id: userId,
          },
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static listPosts() {
    return this.fetchCurrentPortal().then((portal) => {
      const linkComponent = function (url, text) {
        return (
          <a href={url} target="_blank" rel="noreferrer">
            {text || url}
          </a>
        );
      };
      const portalSlug = portal.id.match(/portal_(.*)/)[1];
      const portalUiKitUrl = `https://dev.${portalSlug}.com.br/uikit/`;
      let posts = [
        {
          index: 'c81bb553-bb07-427b-86f5-b0eb75466d49',
          author: "Bem-vindo!",
          body: `
              Seja bem-vindo ao ${portal.name}!
              Neste portal você encontrará uma lista de aplicações/módulos disponíveis em ambiente Petrobras com propósitos específicos de engenharia.
              No painel ao lado encontram-se todas aplicações/módulos disponíveis.
            `,
        },
        {
          index: '84e2883b-ff1a-48cd-9f54-eed90d6ae3ab',
          author: "Site do projeto NavalSUBWEB",
          body: (
            <>
              Acesse o site do projeto NavalSUBWEB para obter mais informações
              sobre os portais e a Plataforma SQUID. O site contém uma área
              focada em desenvolvedores que desejam adicionar novos módulos no
              portal.
              <br />
              <br />
              Links de acesso:{" "}
              {linkComponent(
                "https://petrobrasbr.sharepoint.com/teams/NavalWeb/hotsite",
                "Home page"
              )}{" "}
              e{" "}
              {linkComponent(
                "https://petrobrasbr.sharepoint.com/teams/NavalWeb/hotsite/SitePages/Espa%C3%A7o%20do%20Desenvolvedor.aspx",
                "Espaço do Desenvolvedor"
              )}
              .
            </>
          ),
        },
        {
          index: 'b9334058-ecd1-4cad-b55b-e804c26e1dbf',
          author: "UI-Kit",
          body: (
            <>
              Este portal foi desenvolvido usando o UI-Kit desenvolvido e
              mantido pela Plataforma SQUID. Caso queira saber mais informações,
              acesse a página de documentação em {linkComponent(portalUiKitUrl)}
              .
            </>
          ),
        },
        {
          index: 'b40cd098-b03e-4d79-8694-bc673458c347',
          author: "APIs da Plataforma SQUID",
          body: (
            <>
              A Plataforma SQUID dispõe de uma API disponível para
              desenvolvedores de novos módulos do portal. Para acessar a
              documentação, acesse{" "}
              {linkComponent("https://squid-dev.navalsubweb.com.br/api/docs/")}.
            </>
          ),
        },
      ];
      return posts;
    });
  }

  static authenticate(login, password) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: AUTH,
          variables: {
            portalId: window.SQUID_PORTAL_ID,
            login: login,
            password: password,
          },
        })
        .then((result) => {
          resolve(result.data.authentication.login.token);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static validateToken(token) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: VALIDATE_TOKEN,
          variables: {
            token: token,
          },
        })
        .then((result) => {
          resolve(result.data.authentication.validate);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
