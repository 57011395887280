import React from "react";
import "./App.css";

import { Provider, tokens } from "squid-ui-kit";

import Login from "./modules/Login";
import Application from "./modules/Application";
import LocalApi from "./config/LocalApi";

class App extends React.Component {
  constructor(props) {
    super(props);

    // this.timeout

    this.state = {
      ready: false,
      token: null,
      tokens: tokens,
      showExpirationAlert: false,
    };

    this.onLogout = () => {
      LocalApi.clear();
      this.setState({
        token: null,
      });
    };
  }

  componentDidMount() {
    let timeout = setInterval(() => {
      if (window.tokens) {
        clearInterval(timeout);
        this.setState({
          ready: true,
          token: LocalApi.getToken(),
          tokens: window.tokens,
        });
      }
    }, 100);

  }

  serialize() {}

  render() {
    let content;
    if (this.state.ready === true && this.state.token) {
      content = (
        <Application
          localApps={this.props.localApps}
          token={this.state.token}
          tokens={this.state.tokens}
          onLogout={this.onLogout}
          onExpiration={() => {
            this.onLogout();
            this.setState({
              showExpirationAlert: true,
            });
          }}
        />
      );
    } else if (this.state.ready === true) {
      content = (
        <Login
          tokens={this.state.tokens}
          onLogged={(token) => {
            this.setState({
              token: token,
            });
          }}
          showExpirationAlert={this.state.showExpirationAlert}
          onCloseExpirationAlert={() => {
            this.setState({
              showExpirationAlert: false,
            });
          }}
        />
      );
    }

    let bgImage = "";
    if (this.state.tokens) {
      bgImage = `url('${this.state.tokens.BrandName.toLowerCase()}-bg.png')`;
    }

    return (
      <div
        className="App"
        style={{
          position: "absolute",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
          backgroundImage: bgImage,
          backgroundSize: "cover",
        }}
      >
        <Provider theme={this.state.tokens}>{content}</Provider>
      </div>
    );
  }
}

export default App;
