import React from "react";
import PropTypes from "prop-types";

import HomePresentation from "./Home.presentation";
import CoreBackendApi from "../../config/CoreBackendApi";
import LocalApi from "../../config/LocalApi";
import defaultAvatar from "../../assets/default-avatar.png";

class HomeContainer extends React.Component {
  constructor(props) {
    super(props);

    let user = LocalApi.getUser();

    this.state = {
      name: user.name,
      avatar: user.avatar || defaultAvatar,
      posts: [],
    };

    this.onOpenApp = (index) => {
      if (this.props.onOpenApp) {
        this.props.onOpenApp(index);
      }
    };

    this.onOpenBasicData = (index) => {
      if (this.props.onOpenBasicData) {
        this.props.onOpenBasicData(index);
      }
    };
  }

  componentDidMount() {
    CoreBackendApi.listPosts().then((posts) => {
      this.setState({
        posts: posts,
      });
    });
  }

  render() {
    return (
      <HomePresentation
        tokens={this.props.tokens}
        apps={this.props.apps}
        basicDataApps={this.props.basicDataApps}
        name={this.state.name}
        avatar={this.state.avatar}
        posts={this.state.posts}
        onClickApp={this.onOpenApp}
        onClickBasicData={this.onOpenBasicData}
        onLogout={this.props.onLogout}
        filterUserApplications={this.props.filterUserApplications}
      />
    );
  }
}

HomeContainer.propTypes = {
  apps: PropTypes.arrayOf(PropTypes.object),
  basicDataApps: PropTypes.arrayOf(PropTypes.object),
  filterUserApplications: PropTypes.func,
  onOpenApp: PropTypes.func,
  onLogout: PropTypes.func,
};

HomeContainer.defaultProps = {
  apps: [],
  basicDataApps: undefined,
};

export default HomeContainer;
