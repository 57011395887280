import React from "react";

import ProfilesPresentation from "./Profiles.presentation";

import { TableCell } from "squid-ui-kit";

class ProfilesContainer extends React.Component {
  constructor(props) {
    super(props);

    this.dataSource = [
      {
        name: "Member",
      },
      {
        name: "Admin",
      },
    ];

    this.state = {
      data: this.dataSource,
      columns: [],
    };

    this.onAdd = (e) => {
      console.log("+++++++++++ on add +++++++++++");
    };
  }

  componentDidMount() {
    this.setState({
      columns: [
        {
          dataIndex: "name",
          label: "NOME",
          flex: "1",
          sortable: true,
          onSort: this.onSort.bind(this),
          searchable: false,
          cellRenderer: this.renderName.bind(this),
          selected: false,
        },
      ],
    });
  }

  renderName(text) {
    return <TableCell>{text}</TableCell>;
  }
  onSort(e) {
    let sortedData = [];

    if (e.direction === "asc") {
      sortedData = []
        .concat(this.state.data)
        .sort((a, b) => (a[e.dataIndex] > b[e.dataIndex] ? 1 : -1));
    } else if (e.direction === "desc") {
      sortedData = []
        .concat(this.state.data)
        .sort((a, b) => (a[e.dataIndex] < b[e.dataIndex] ? 1 : -1));
    } else {
      sortedData = this.dataSource;
    }
    this.setState({
      data: sortedData,
    });
  }

  render() {
    return (
      <ProfilesPresentation
        tokens={this.props.tokens}
        profiles={this.state.data}
        columns={this.state.columns}
        onAdd={this.onAdd}
      />
    );
  }
}

ProfilesContainer.propTypes = {};

ProfilesContainer.defaultProps = {};

export default ProfilesContainer;
