import React from "react";
import PropTypes from "prop-types";

import AppFramePresentation from "./AppFrame.presentation";

class AppFrameContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <AppFramePresentation
        src={this.props.src}
        srcParams={this.props.srcParams}
        tokens={this.props.tokens}
        token={this.props.token}
        active={this.props.active}
        onExpiration={this.props.onExpiration}
      />
    );
  }
}

AppFrameContainer.propTypes = {
  src: PropTypes.string,
  active: PropTypes.bool,
  onExpiration: PropTypes.func,
};

AppFrameContainer.defaultProps = {
  src: "",
  active: false,
};

export default AppFrameContainer;
