import React from "react";
import PropTypes from "prop-types";
import ls from "local-storage";

import SetupPresentation from "./Setup.presentation";
import CoreBackendApi from "../../config/CoreBackendApi";

class SetupContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showWindowFormUser: false,
      showWindowFormLocalApplication: false,
      localApp: null,
      localApps: [],
      user: null,
      users: [],
      groups: [],

      // App management
      showWindowFormApplication: false,
      selectedApplication: null,
    };

    this.onAddUser = () => {
      this.setState({
        showWindowFormUser: true,
      });
    };
    this.onAddApplication = () => {
      this.setState({
        showWindowFormApplication: true,
        selectedApplication: null,
      });
    };
    this.onAddLocalApplication = () => {
      this.setState({
        showWindowFormLocalApplication: true,
      });
    };
    this.onEditLocalApplication = (localApp) => {
      this.setState({
        showWindowFormLocalApplication: true,
        localApp: localApp,
      });
    };
    this.onRemoveLocalApplication = (name) => {
      let localApps;
      if (this.state.localApps && this.state.localApps.length > 0) {
        Object.keys(this.state.localApps).forEach((index) => {
          if (this.state.localApps[index].name !== name) {
            if (!localApps) {
              localApps = [];
            }
            localApps.push(this.state.localApps[index]);
          }
        });
      }
      if (localApps) {
        ls.set("local-apps", localApps);
      } else {
        ls.remove("local-apps");
      }
      this.setState(
        {
          showWindowFormLocalApplication: false,
          localApps: localApps,
          localApp: null,
        },
        () => {
          this.props.onUpdateApps();
        }
      );
    };
    this.onEditUser = (user) => {
      this.setState({
        showWindowFormUser: true,
        user: user,
      });
    };
    this.onDeleteUser = (user) => {
      CoreBackendApi.deleteUser(user.id)
        .then(() => {
          let users = [];
          Object.keys(this.state.users).forEach((index) => {
            if (this.state.users[index].id !== user.id) {
              users.push(this.state.users[index]);
            }
          });
          this.setState(
            {
              users: [],
            },
            () => {
              users.sort((a, b) => a.name > b.name);
              // this.listUsers()
              this.setState({
                users,
              });
            }
          );
        })
        .catch((error) => {
          console.log("+++++++++++ ERROR CALLBACK +++++++++++", error);
        });
    };
    this.onProfileChange = (e) => {
      let user;
      Object.keys(this.state.users).forEach((index) => {
        if (this.state.users[index].id === e.name) {
          user = this.state.users[index];
        }
      });
      if (user) {
        if (user.groups && user.groups.length > 0) {
          CoreBackendApi.leaveGroupUser(user.id, user.groups[0].id)
            .then(() => {
              return CoreBackendApi.joinGroupUser(user.id, e.value);
            })
            .then(() => {
              this.setState(
                {
                  users: [],
                },
                () => {
                  this.listUsers();
                }
              );
            })
            .catch((error) => {
              console.log("+++++++++++ ERROR CALLBACK +++++++++++", error);
            });
        } else {
          CoreBackendApi.joinGroupUser(user.id, e.value)
            .then(() => {
              this.setState(
                {
                  users: [],
                },
                () => {
                  this.listUsers();
                }
              );
            })
            .catch((error) => {
              console.log("+++++++++++ ERROR CALLBACK +++++++++++", error);
            });
        }
      }
    };
    this.onCloseWindowFormUser = () => {
      this.setState({
        showWindowFormUser: false,
        user: null,
      });
    };
    this.onCloseWindowFormLocalApplication = () => {
      this.setState({
        showWindowFormLocalApplication: false,
        localApp: null,
      });
    };

    this.onCloseWindowFormApplication = () => {
      this.setState({
        showWindowFormApplication: false,
        selectedApplication: undefined,
      });
    }
    this.onCreateApplication = (app) => {
      return CoreBackendApi.createApplication(app)
        .then(() => {
          this.setState({
            showWindowFormApplication: false,
            selectedApplication: undefined,
          });
        })
    };
    this.onSelectApplication = (app) => {
      this.setState({
        showWindowFormApplication: true,
        selectedApplication: app,
      });
    };
    this.onUpdateApplication = (app) => {
      return CoreBackendApi.updateApplication(app)
        .then(() => {
          this.setState({
            showWindowFormApplication: false,
            selectedApplication: undefined,
          });
        })
    };
    this.onRemoveApplication = (app) => {
      return CoreBackendApi.deleteApplication(app)
        .then(() => {
          this.setState({
            showWindowFormApplication: false,
            selectedApplication: undefined,
          });
        })
    }

    this.onSaveLocalApplication = (localApp) => {
      let localApps = ls.get("local-apps");
      if (localApps && localApps.length > 0) {
        let novo = true;
        Object.keys(localApps).forEach((index) => {
          if (localApps[index].name === localApp.name) {
            localApps[index] = localApp;
            novo = false;
          }
        });
        if (novo === true) {
          localApps.push(localApp);
        }
      } else {
        localApps = [localApp];
      }
      ls.set("local-apps", localApps);
      this.setState(
        {
          showWindowFormLocalApplication: false,
          localApps: localApps,
          localApp: null,
        },
        () => {
          this.props.onUpdateApps();
        }
      );
    };

    this.onSaveUser = (user, success, fail, keepWindowOpened) => {
      if (user && user.id && user.id !== "") {
        let userId = user.id;
        delete user.id;
        delete user.profile;
        CoreBackendApi.editUser(userId, user)
          .then(() => {
            this.setState(
              {
                showWindowFormUser: keepWindowOpened,
                user: null,
                users: [],
              },
              () => {
                success();
                this.listUsers();
              }
            );
          })
          .catch((error) => {
            fail(error);
          });
      } else {
        // user.groupId = user.profile
        const profileId = user.profile;
        delete user.id;
        delete user.profile;
        CoreBackendApi.addUser(user, fail)
          .then((data) => {
            return CoreBackendApi.joinGroupUser(
              data.users.create.id,
              profileId
            );
          })
          .then(() => {
            this.setState(
              {
                showWindowFormUser: keepWindowOpened,
                user: null,
                users: [],
              },
              () => {
                success();
                this.listUsers();
              }
            );
          })
          .catch((error) => {
            fail(error);
          });
      }
    };
  }

  componentDidMount() {
    CoreBackendApi.listGroups()
      .then((groups) => {
        this.setState(
          {
            groups: groups,
          },
          () => {
            this.listUsers();
          }
        );
      })
      .catch((error) => {
        console.log("+++++++++++ ERROR CALLBACK +++++++++++", error);
      });
    let localApps = ls.get("local-apps");
    if (localApps) {
      console.log("+++++++++++ LOCAL DATA +++++++++++", localApps);
      // NOTE trabalhar o local-apps como um array
      this.setState({
        localApps,
      });
    }
  }

  listUsers() {
    CoreBackendApi.listUsers()
      .then((usersSquid) => {
        usersSquid.sort((a, b) => a.name > b.name);
        this.setState({
          users: usersSquid,
        });
      })
      .catch((error) => {
        console.log("+++++++++++ ERROR CALLBACK +++++++++++", error);
      });
  }
  

  render() {
    return (
      <SetupPresentation
        isAdmin={this.props.isAdmin}
        tokens={this.props.tokens}
        user={this.state.user}
        groups={this.state.groups}
        users={this.state.users}
        localApps={this.state.localApps}
        localApp={this.state.localApp}
        showWindowFormUser={this.state.showWindowFormUser}
        showWindowFormLocalApplication={
          this.state.showWindowFormLocalApplication
        }
        onAddUser={this.onAddUser}
        onUpdateApps={this.props.onUpdateApps}
        onAddLocalApplication={this.onAddLocalApplication}
        onEditLocalApplication={this.onEditLocalApplication}
        onRemoveLocalApplication={this.onRemoveLocalApplication}
        onEditUser={this.onEditUser}
        onDeleteUser={this.onDeleteUser}
        onProfileChange={this.onProfileChange}
        onCloseWindowFormUser={this.onCloseWindowFormUser}
        onCloseWindowFormLocalApplication={
          this.onCloseWindowFormLocalApplication
        }
        onSaveUser={this.onSaveUser}
        onSaveLocalApplication={this.onSaveLocalApplication}

        // Apps management
        onAddApplication={this.onAddApplication}
        showWindowFormApplication={this.state.showWindowFormApplication}
        onCreateApplication={this.onCreateApplication}
        onCloseWindowFormApplication={this.onCloseWindowFormApplication}
        onSelectApplication={this.onSelectApplication}
        selectedApplication={this.state.selectedApplication}
        onUpdateApplication={this.onUpdateApplication}
        onRemoveApplication={this.onRemoveApplication}
      />
    );
  }
}

SetupContainer.propTypes = {
  isAdmin: PropTypes.bool,
  onUpdateApps: PropTypes.func,
};

SetupContainer.defaultProps = {
  isAdmin: false,
};

export default SetupContainer;
