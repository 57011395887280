import React from "react";
import PropTypes from "prop-types";

import UsersPresentation from "./Users.presentation";

import {
  TableCell,
  Checkbox,
  Dropdown,
  ListItem,
  IconButton,
} from "squid-ui-kit";
import LocalApi from "../../config/LocalApi";

class UsersContainer extends React.Component {
  constructor(props) {
    super(props);

    let groups = props.groups.map((group) => {
      return <ListItem key={group.id} title={group.name} subtitle="" />;
    });

    this.dataSource = [];
    this.columns = [
      {
        dataIndex: "name",
        label: "USUÁRIOS",
        flex: "1",
        // sortable: true,
        // onSort: this.onSort.bind(this),
        searchable: false,
        cellRenderer: this.renderUser.bind(this),
        selected: false,
      },
      {
        dataIndex: "email",
        label: "EMAIL",
        flex: "2",
        // sortable: false,
        // onSort: null,
        searchable: false,
        cellRenderer: this.renderEmail.bind(this),
        selected: false,
      },
      {
        dataIndex: "petrobrasKey",
        label: "CHAVE",
        width: "150px",
        // sortable: false,
        // onSort: null,
        searchable: false,
        cellRenderer: this.renderKey.bind(this),
        selected: false,
      },
      // {
      //   dataIndex: "access",
      //   label: "ACESSO",
      //   width: "80px",
      //   sortable: false,
      //   onSort: null,
      //   searchable: false,
      //   cellRenderer: this.renderAccess.bind(this),
      //   selected: false,
      // },
      {
        dataIndex: "profile",
        label: "PERFIL",
        width: "150px",
        // sortable: false,
        // onSort: null,
        searchable: false,
        cellRenderer: this.renderProfile.bind(this),
        selected: false,
      },
      {
        dataIndex: "actions",
        label: "AÇÕES",
        width: "80px",
        // sortable: false,
        // onSort: null,
        searchable: false,
        cellRenderer: this.renderActions.bind(this),
        selected: false,
      },
    ];

    this.state = {
      currentPage: 0,
      itemsPerPage: 15,
      rowToDelete: null,
      warning: "",
      data: this.props.users,
      columns: this.columns,
      groups: groups,
      usersLength: this.props.users.length,
      filteredUsers: this.props.users,
      searchValue: "",
    };

    this.onAdd = () => {
      if (this.props.onAdd) {
        this.props.onAdd();
      }
    };
    this.onProfileChange = (e) => {
      if (this.props.onProfileChange) {
        this.props.onProfileChange(e);
      }
    };
    this.onSearch = (e) => {
      const usersFound = this.props.users.filter((user) => {
        if (
             user.name.toUpperCase().includes(e.value.toUpperCase())
          || user.petrobrasKey.toUpperCase().includes(e.value.toUpperCase())
        ) {
          return true;
        }
        else {
          if (user.email) {
            if (user.email.toUpperCase().includes(e.value.toUpperCase())) {
              return true;
            }
          }
        }

        return false;
      })

      this.setState({
        filteredUsers: usersFound,
      });
    }
    this.onSearchClear = (e) => {
      this.setState({
        searchValue: "",
        filteredUsers: this.props.users,
      });
    }    
    this.onSearchChange = (e) => {
      this.setState({
        searchValue: e.value,
      });
    }
    this.getCurrentPage = (page) => {
      this.setState({
        currentPage: page,
      });
    }
    this.setItemsPerPage = (e) => {
      this.setState({
        itemsPerPage: parseInt(e.value),
      });
    }
  }

  itsMe(user) {
    const me = LocalApi.getUser();
    return me.id === user.id
  }

  renderUser(text, record, index) {
    return <TableCell>{text}</TableCell>;
  }
  renderEmail(text, record, index) {
    return <TableCell>{text}</TableCell>;
  }
  renderKey(text, record, index) {
    return <TableCell>{text}</TableCell>;
  }
  renderAccess(text, record, index) {
    return (
      <TableCell horizontalAlign="center">
        <Checkbox
          checked={record.access}
          invertedColors={true}
          disabled={true}
        />
      </TableCell>
    );
  }
  renderProfile(text, record, index) {
    let profile;
    if (record.groups && record.groups.length > 0) {
      profile = record.groups[0].id;
    }
    return (
      <TableCell
        horizontalAlign="center"
        overflow="unset"
      >
        <Dropdown
          disabled={this.itsMe(record)}
          placeholder="Perfil"
          name={record.id}
          width="100%"
          size="small"
          selectedKey={profile}
          onChange={this.onProfileChange}
        >
          {this.state.groups}
        </Dropdown>
      </TableCell>
    );
  }
  renderActions(text, record, index) {
    return (
      <TableCell horizontalAlign="center">
        <IconButton
          disabled={this.itsMe(record)}
          icon="delete"
          onClick={this.onDelete.bind(this, index)}
        />
      </TableCell>
    );
  }
  // onSort(e) {
  //   let sortedData = [];

  //   if (e.direction === "asc") {
  //     sortedData = []
  //       .concat(this.props.users)
  //       .sort((a, b) => (a[e.dataIndex] > b[e.dataIndex] ? 1 : -1));
  //   } else if (e.direction === "desc") {
  //     sortedData = []
  //       .concat(this.props.users)
  //       .sort((a, b) => (a[e.dataIndex] < b[e.dataIndex] ? 1 : -1));
  //   } else {
  //     sortedData = this.props.users;
  //   }
  //   this.setState({
  //     data: sortedData,
  //   });
  // }

  onEdit(row) {
    if (this.props.onEdit) {
      // Compute the index for filteredUsers
      const filterIndex = this.state.itemsPerPage * this.state.currentPage + parseInt(row);

      // Get the user from filteredUsers
      const user = this.state.filteredUsers[filterIndex];

      // Perform the edit
      this.props.onEdit(user);
    }
  }

  onDelete(row) {
    if (this.props.onDelete) {
      // Compute the index for filteredUsers
      const filterIndex = this.state.itemsPerPage * this.state.currentPage + parseInt(row);

      // Get the user from filteredUsers
      const user = this.state.filteredUsers[filterIndex];

      // Find the corresponding user index in this.state.data
      const dataIndex = this.state.data.findIndex((u) => u.petrobrasKey === user.petrobrasKey);

      // Update the state
      this.setState({
        warning: `Deseja realmente remover o usuário ${user.name} (${user.petrobrasKey}) ?`,
        rowToDelete: dataIndex,
      });
    }
  }
  onConfirmDelete() {
    if (this.props.onDelete) {
      this.props.onDelete(this.props.users[this.state.rowToDelete]);
    }
  }
  onCancelDelete() {
    this.setState({
      warning: "",
      rowToDelete: null,
    });
  }

  render() {
    return (
      <UsersPresentation
        warning={this.state.warning}
        tokens={this.props.tokens}
        users={this.state.filteredUsers}
        columns={this.state.columns}
        onConfirmDelete={this.onConfirmDelete.bind(this)}
        onCancelDelete={this.onCancelDelete.bind(this)}
        onAdd={this.onAdd}
        onSearch={this.onSearch}
        onSearchClear={this.onSearchClear}
        onSearchChange={this.onSearchChange}
        searchValue={this.state.searchValue}
        getCurrentPage={this.getCurrentPage}
        itemsPerPage={this.state.itemsPerPage}
        setItemsPerPage={this.setItemsPerPage}
      />
    );
  }
}

UsersContainer.propTypes = {
  users: PropTypes.array,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onProfileChange: PropTypes.func,
};

UsersContainer.defaultProps = {
  users: [],
};

export default UsersContainer;
