import React from "react";
import PropTypes from "prop-types";

import { 
  Alert, 
  Container,
  Panel, 
  tokens,
  Text,
  Spacer,
  Icon,
  IconButton,
  TextField,
} from "squid-ui-kit";

import PaginatedTable from "../../components/PaginatedTable";

class UsersPresentation extends React.Component {
  constructor(props) {
    super(props);

    this.searchValueRef = React.createRef();

    this.state = {
      isSearchActive: false,
    };
  }

  render() {
    let alert;
    if (this.props.warning && this.props.warning !== "") {
      alert = (
        <Alert
          hasBorder={true}
          title="Atenção"
          message={this.props.warning}
          confirm={true}
          closable={false}
          confirmLabel="Sim"
          cancelLabel="Cancelar"
          onConfirm={this.props.onConfirmDelete}
          onCancel={this.props.onCancelDelete}
          bodyType="content"
          padding={tokens.PrimaryPadding} // "10px"
        />
      );
    }

    const table = () => {
      if (this.props.users.length > 0) {
        return (
          <PaginatedTable
            itemsPerPage={this.props.itemsPerPage}
            setItemsPerPage={this.props.setItemsPerPage}
            dataSource={this.props.users}
            columns={this.props.columns}
            getCurrentPage={this.props.getCurrentPage}
            isSearchActive={this.state.isSearchActive}
          />
        );
      } else {
        return (
          <Container height="100%" width="100%" verticalAlign="center" horizontalAlign="center">
            <Text size="large">
              Nenhum usuário encontrado. Por favor, refaça a busca.
            </Text>
          </Container>
        );
      }
    };

    if (this.props.columns && this.props.columns.length > 0) {
      return (
        <Container
          width="100%"
          flex="1"
          // height="100%"
          direction="vertical"
          verticalAlign="top"
          bodyType="neutral"
          margin="0px"
          padding="0px"
        >
          <Container height="25px" bodyType="active" direction="horizontal" width="100%" verticalAlign="center" padding="10px">
            <Text bold> USUÁRIOS </Text>
            <Spacer/>
            <Container bodyType="content" width="fit-content" direction="horizontal">
              {this.state.isSearchActive && 
                <IconButton
                  icon="close"
                  invertedColors
                  onClick={(e) => {
                      this.setState({
                        isSearchActive: false,
                      }, () => {
                        this.searchValueRef.current.focus();
                        this.props.onSearchClear(e);
                      });
                    }
                  }
                />
              }
              <TextField
                ref={this.searchValueRef}
                placeholder="Nome, e-mail ou chave"
                value={this.props.searchValue}
                onChange={this.props.onSearchChange}
                onEnter={(e) => {
                  let activate = true;
                  if (e.value === "") {
                    activate = false;
                  }
                  this.setState({
                    isSearchActive: activate,
                  }, this.props.onSearch(e));
                }
                }
                onSearch={(e) => {
                    let activate = true;
                    if (e.value === "") {
                      activate = false;
                    }
                    this.setState({
                      isSearchActive: activate,
                    }, this.props.onSearch(e));
                  }
                }
                invertedColors
                searchButton
              />
            </Container>
            <Container bodyType="neutral" direction="horizontal" verticalAlign="center" onClick={this.props.onAdd}>
              <Icon icon="add"/>
              <Text bold> NOVO </Text>
            </Container>
          </Container>
          {table()}
          {alert}
        </Container>
      );
    } else {
      return (
        <div>
          <Panel
            label="Usuários"
            width="100%"
            flex="1"
            direction="vertical"
            verticalAlign="top"
            bodyType="primary"
          ></Panel>
          {alert}
        </div>
      );
    }
  }
}

UsersPresentation.propTypes = {
  warning: PropTypes.string,
  users: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.object),
  onConfirmDelete: PropTypes.func,
  onCancelDelete: PropTypes.func,
  onAdd: PropTypes.func,
  onSearch: PropTypes.func,
  onSearchClear: PropTypes.func,
  onSearchChange: PropTypes.func,
  searchValue: PropTypes.string,
  getCurrentPage: PropTypes.func,
  itemsPerPage: PropTypes.number,
  setItemsPerPage: PropTypes.func,
};

UsersPresentation.defaultProps = {
  warning: "",
  users: [],
  columns: [],
};

export default UsersPresentation;
