import React from "react";
import PropTypes from "prop-types";
import Color from "color";
import uniqid from "uniqid";

import { ExclusionAlert } from "../../components";

import { Container, Spacer, tokens, Tooltip } from "squid-ui-kit";

class AppButtonPresentation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      appManagers: undefined,
      isErrorModalOpen: false,
    };

    this.handleErrorModal = () => {
      this.setState({
        isErrorModalOpen: !this.state.isErrorModalOpen,
      });
    };

    this.onClick = async (e) => {
      if (this.props.onClick) {
        if (!this.state.loading && this.props.index.length === 36) {
          try {
            this.setState({
              loading: true,
            });
            this.props.onClick(this.props.index);
          } catch {
            this.setState({
              isErrorModalOpen: true,
            });
          } finally {
            this.setState({
              loading: false,
            });
          }
        } else {
          this.props.onClick(this.props.index);
        }
      }
    };

    this.onClose = (e) => {
      if (this.props.onClose) {
        this.props.onClose(this.props.index);
      }
    };
  }

  render() {
    return (
      <div
        style={{
          width: "80px",
          height: "auto",
          borderRadius: this.props.tokens.RadiusPrimary,
          // backgroundColor: Color(this.props.tokens.ColorWhite).alpha(0.9),
          cursor: "pointer",
        }}
      >
        <Container
          width="100%"
          height="100%"
          direction="vertical"
          horizontalAlign="center"
          verticalAlign="top"
          bodyType="neutral"
          margin="0px"
          name={`app-button-${this.props.index}`}
          // padding="5px"
          onClick={this.onClick}
          disabled={this.state.loading}
        >
          <div
            style={{
              width: "80px",
              height: "80px",
              borderRadius: this.props.tokens.RadiusPrimary,
              // backgroundColor: this.props.tokens.ColorPrimary,
              cursor: "pointer",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: this.props.tokens.RadiusPrimary,
                // borderRadius: "20px",
                backgroundImage:
                  "radial-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.8))",
              }}
            >
              <img
                alt=""
                key={uniqid.time()}
                style={{
                  width: "80px",
                  height: "80px",
                }}
                src={this.props.icon}
              />
            </div>
          </div>
          <Spacer direction="vertical" size={tokens.PrimaryPadding} />
          <Container
            flex="1"
            width="100%"
            direction="horizontal"
            horizontalAlign="center"
            verticalAlign="center"
            bodyType="neutral"
            margin="0px"
            padding="3px"
          >
            <span
              style={{
                textAlign: "center",
                fontFamily: this.props.tokens.FontBodyPrimaryFamily,
                fontSize: this.props.tokens.FontBodyPrimarySize,
                fontWeight: this.props.tokens.FontBodyPrimaryWeight,
                color: Color(this.props.tokens.ColorWhite).alpha(0.9),
                cursor: "pointer",
                textOverflow: "ellipsis",
                width: "80px",
                overflow: "hidden",
              }}
            >
              <Tooltip placement="bottom" title={this.props.label}>
                {this.props.label}
              </Tooltip>
            </span>
          </Container>
        </Container>

        {this.state.isErrorModalOpen && (
          <ExclusionAlert
            exclusionText={
              "Um erro ocorreu ao requisitar as credenciais para o aplicativo."
            }
            title="Alerta"
            onCancel={this.handleErrorModal}
            onlyConfirmation
          />
        )}
      </div>
    );
  }
}

AppButtonPresentation.propTypes = {
  index: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.any,
  compact: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

AppButtonPresentation.defaultProps = {
  index: "",
  label: "AppButton",
  icon: null,
  compact: false,
  active: false,
};

export default AppButtonPresentation;
