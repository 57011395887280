import React from "react";
import PropTypes from "prop-types";
import uniqid from 'uniqid';

import { Container, Spacer, Panel, Alert, tokens } from "squid-ui-kit";

import Applications from "../Applications";
import Users from "../Users";
import Profiles from "../Profiles";
import WindowFormUser from "../Users/components/WindowFormUser";
import WindowFormLocalApplication from "../Applications/components/WindowFormLocalApplication";
import WindowFormApplication from "../Applications/components/WindowFormApplication";

class SetupPresentation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmRemove: null,
      appsReloadCounter: 0,
    };


    this.updateApplicationList = () => {
      this.props.onUpdateApps();
      // this.setState({
      //   appsReloadCounter: this.state.appsReloadCounter+1,
      // });
    }

    this.onRemove = (name) => {
      if (this.props.onRemoveLocalApplication) {
        let confirmRemove = (
          <Alert
            hasBorder={true}
            bodyType="content"
            padding={tokens.PrimaryPadding}
            message={
              <Container
                width="100%"
                height="100%"
                direction="vertical"
                verticalAlign="top"
                horizontalAlign="left"
                bodyType="neutral"
              >
                Deseja realmente remover a aplicação local?
              </Container>
            }
            confirm={true}
            cancelLabel="Cancelar"
            confirmLabel="Sim"
            onCancel={() => {
              this.setState({
                confirmRemove: null,
              });
            }}
            onClose={() => {
              this.setState({
                confirmRemove: null,
              });
            }}
            onConfirm={() => {
              this.props.onRemoveLocalApplication(name);
              this.setState({
                confirmRemove: null,
              });
            }}
            title="Remover Aplicação Local"
          />
        );
        this.setState({
          confirmRemove: confirmRemove,
        });
      }
    };
    this.onRemoveApplication = (application) => {
      if (this.props.onRemoveApplication) {
        let confirmRemove = (
          <Alert
            hasBorder={true}
            bodyType="content"
            padding={tokens.PrimaryPadding}
            message={
              <Container
                width="100%"
                height="100%"
                direction="vertical"
                verticalAlign="top"
                horizontalAlign="left"
                bodyType="neutral"
              >
                Deseja realmente remover {application.name}?
              </Container>
            }
            confirm={true}
            cancelLabel="Cancelar"
            confirmLabel="Sim"
            onCancel={() => {
              this.setState({
                confirmRemove: null,
              });
            }}
            onClose={() => {
              this.setState({
                confirmRemove: null,
              });
            }}
            onConfirm={() => {
              this.props.onRemoveApplication(application)
                .then(() => {
                  this.updateApplicationList();
                  this.setState({
                    confirmRemove: null,
                  });
                });
            }}
            title="Remover Aplicação"
          />
        );
        this.setState({
          confirmRemove: confirmRemove,
        });
      }
    };
  }

  render() {
    let windowFormUser;
    if (this.props.showWindowFormUser === true) {
      let profile;
      if (
        this.props.user &&
        this.props.user.groups &&
        this.props.user.groups.length > 0
      ) {
        profile = this.props.user.groups[0].id;
      }
      windowFormUser = (
        <WindowFormUser
          tokens={this.props.tokens}
          mode={this.props.user ? "edit" : "create"}
          petrobrasKey={this.props.user ? this.props.user.petrobrasKey : ""}
          name={this.props.user ? this.props.user.name : ""}
          email={this.props.user ? this.props.user.email : ""}
          id={this.props.user ? this.props.user.id : ""}
          profile={profile}
          onClose={this.props.onCloseWindowFormUser}
          onSave={this.props.onSaveUser}
        />
      );
    }

    const applicationsComponent = (
      <Applications
        key={`apps-component-reload-${this.state.appsReloadCounter}`}
        isAdmin={this.props.isAdmin}
        onAdd={this.props.onAddApplication}
        localApps={this.props.localApps}
        onAddLocal={this.props.onAddLocalApplication}
        onEditLocal={this.props.onEditLocalApplication}

        // App management
        onSelectApplication={this.props.onSelectApplication}
      />
    );

    let windowFormApplication;
    if (this.props.showWindowFormApplication === true) {
      const onCreateApplication = (app) => {
        return this.props.onCreateApplication(app)
          .then(this.updateApplicationList);
      }
      const onUpdateApplication = (app) => {
        return this.props.onUpdateApplication(app)
          .then(this.updateApplicationList);
      }
      windowFormApplication = (
        <WindowFormApplication
          key={uniqid.time().toString()}
          tokens={this.props.tokens}
          application={this.props.selectedApplication}
          onClose={this.props.onCloseWindowFormApplication}
          onCreate={onCreateApplication}
          onUpdate={onUpdateApplication}
          onRemove={this.onRemoveApplication}
        />
      );
    }

    let windowFormLocalApplication;
    if (this.props.showWindowFormLocalApplication === true) {
      windowFormLocalApplication = (
        <WindowFormLocalApplication
          tokens={this.props.tokens}
          mode={this.props.localApp ? "edit" : "create"}
          name={this.props.localApp ? this.props.localApp.name : ""}
          url={this.props.localApp ? this.props.localApp.url : ""}
          params={this.props.localApp ? this.props.localApp.params : ""}
          onClose={this.props.onCloseWindowFormLocalApplication}
          onSave={this.props.onSaveLocalApplication}
          onRemove={this.onRemove}
        />
      );
    }

    let users;
    let profiles;
    if (this.props.isAdmin) {
      if (this.props.users && this.props.users.length > 0) {
        users = (
          <Users
            groups={this.props.groups}
            users={this.props.users}
            onAdd={this.props.onAddUser}
            onEdit={this.props.onEditUser}
            onDelete={this.props.onDeleteUser}
            onProfileChange={this.props.onProfileChange}
          />
        );
      } else {
        users = (
          <Panel
            label="Usuários"
            width="100%"
            flex="1"
            direction="vertical"
            verticalAlign="top"
            bodyType="primary"
          ></Panel>
        );
      }
      profiles = <Profiles />;
    }

    return (
      <Container
        width="100%"
        height="100%"
        direction="vertical"
        verticalAlign="top"
        bodyType="neutral"
        margin="0px"
        padding="18px"
      >
        {applicationsComponent}
        <Spacer direction="vertical" size="18px" />
        {users}
        <Spacer direction="vertical" size="18px" />
        {profiles}
        {windowFormUser}
        {windowFormApplication}
        {windowFormLocalApplication}
        {this.state.confirmRemove}
      </Container>
    );
  }
}

SetupPresentation.propTypes = {
  isAdmin: PropTypes.bool,
  showWindowFormUser: PropTypes.bool,
  showWindowFormLocalApplication: PropTypes.bool,
  user: PropTypes.object,
  groups: PropTypes.array,
  users: PropTypes.array,
  onAdd: PropTypes.func,
  localApps: PropTypes.array,
  onAddLocalApplication: PropTypes.func,
  onEditLocalApplication: PropTypes.func,
  onRemoveLocalApplication: PropTypes.func,
  onAddUser: PropTypes.func,
  onEditUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  onProfileChange: PropTypes.func,
  onCloseWindowFormUser: PropTypes.func,
  onCloseWindowFormLocalApplication: PropTypes.func,
  onSaveUser: PropTypes.func,

  // Apps management
  showWindowFormApplication: PropTypes.bool,
  onCreateApplication: PropTypes.func,
  onCloseWindowFormApplication: PropTypes.func,
  onSelectApplication: PropTypes.func,
  selectedApplication: PropTypes.object,
  onUpdateApplication: PropTypes.func,
  onRemoveApplication: PropTypes.func,
};

SetupPresentation.defaultProps = {
  isAdmin: false,
  showWindowFormUser: false,
  showWindowFormLocalApplication: false,
  user: null,
  groups: [],
  users: [],
  localApps: [],

  // Apps management
  showWindowFormApplication: false,
  selectedApplication: null,
};

export default SetupPresentation;
