import React from "react";
import PropTypes from "prop-types";

import CoreBackendApi from "../../config/CoreBackendApi";
import ApplicationsPresentation from "./Applications.presentation";

import { TableCell, Checkbox } from "squid-ui-kit";

class ApplicationsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.dataSource = [
      // {
      //     label: 'Sistemas Navais',
      //     src: '/app/naval-systems/',
      //     active: true,
      //     activeToMe: true,
      // }
    ];
    this.columns = [
      {
        dataIndex: "name",
        label: "NOME",
        flex: "1",
        sortable: true,
        onSort: this.onSort.bind(this),
        searchable: false,
        cellRenderer: this.renderName.bind(this),
        selected: false,
      },
      {
        dataIndex: "url",
        label: "URL",
        flex: "2",
        sortable: false,
        onSort: null,
        searchable: false,
        cellRenderer: this.renderUrl.bind(this),
        selected: false,
      },
      {
        dataIndex: "type",
        label: "TIPO",
        flex: "1",
        sortable: false,
        onSort: null,
        searchable: false,
        cellRenderer: this.renderType.bind(this),
        selected: false,
      },
      // {
      //   dataIndex: "active",
      //   label: "ATIVO NO PORTAL",
      //   width: "100px",
      //   sortable: false,
      //   onSort: null,
      //   searchable: false,
      //   cellRenderer: this.renderActive.bind(this),
      //   selected: false,
      // },
      // {
      //   dataIndex: "activeToMe",
      //   label: "ATIVO PRA MIM",
      //   width: "100px",
      //   sortable: false,
      //   onSort: null,
      //   searchable: false,
      //   cellRenderer: this.renderActiveToMe.bind(this),
      //   selected: false,
      // },
    ];
    this.localColumns = [
      {
        dataIndex: "name",
        label: "NOME",
        flex: "1",
        sortable: true,
        onSort: this.onSort.bind(this),
        searchable: false,
        cellRenderer: this.renderName.bind(this),
        selected: false,
      },
      {
        dataIndex: "url",
        label: "URL",
        flex: "3",
        sortable: false,
        onSort: null,
        searchable: false,
        cellRenderer: this.renderUrl.bind(this),
        selected: false,
      },
      {
        dataIndex: "params",
        label: "Parâmetros",
        flex: "2",
        sortable: false,
        onSort: null,
        searchable: false,
        cellRenderer: this.renderParams.bind(this),
        selected: false,
      },
    ];

    this.state = {
      data: this.dataSource,
      columns: this.columns,
      localColumns: this.localColumns,
    };

    this.onAdd = (e) => {
      if (this.props.onAdd) {
        this.props.onAdd();
      }
    };
    this.onAddLocal = (e) => {
      if (this.props.onAddLocal) {
        this.props.onAddLocal();
      }
    };
    this.onEditLocal = (localApp) => {
      if (this.props.onEditLocal) {
        this.props.onEditLocal(localApp);
      }
    };

    this.onSelectApplication = (app) => {
      if (this.props.onSelectApplication) {
        this.props.onSelectApplication(app);
      }
    };
  }

  componentDidMount() {
    CoreBackendApi.fetchAllApps()
      .then((appsSquid) => {
        this.dataSource = [];
        Object.keys(appsSquid).forEach((index) => {
          if (
            appsSquid[index].index !== "home" &&
            appsSquid[index].index !== "setup" &&
            appsSquid[index].index !== "healthCheck"
          ) {
            this.dataSource.push(appsSquid[index]);
          }
        });
        this.setState(
          {
            data: this.dataSource,
            columns: [],
          },
          () => {
            this.setState({
              columns: this.columns,
            });
          }
        );
      })
      .catch((error) => {
        console.log("+++++++++++ ERROR CALLBACK +++++++++++", error);
      });
  }

  renderName(text, record, index) {
    return <TableCell>{text}</TableCell>;
  }
  renderUrl(text, record, index) {
    return <TableCell>{text}</TableCell>;
  }
  renderType(type, record, index) {
    let typeName;
    switch (type.id) {
      case 1:
        typeName = "Dado básico";
        break;
      default:
        typeName = "Aplicação";
        break;
    }
    return <TableCell>{typeName}</TableCell>;
  }
  renderParams(text, record, index) {
    return <TableCell>{text}</TableCell>;
  }
  renderActive(text, record, index) {
    return (
      <TableCell horizontalAlign="center">
        <Checkbox
          checked={record.active}
          invertedColors={true}
          disabled={true}
        />
      </TableCell>
    );
  }
  renderActiveToMe(text, record, index) {
    return (
      <TableCell horizontalAlign="center">
        <Checkbox
          checked={record.activeToMe}
          invertedColors={true}
          disabled={true}
        />
      </TableCell>
    );
  }
  onSort(e) {
    let sortedData = [];

    if (e.direction === "asc") {
      sortedData = []
        .concat(this.state.data)
        .sort((a, b) => (a[e.dataIndex] > b[e.dataIndex] ? 1 : -1));
    } else if (e.direction === "desc") {
      sortedData = []
        .concat(this.state.data)
        .sort((a, b) => (a[e.dataIndex] < b[e.dataIndex] ? 1 : -1));
    } else {
      sortedData = this.dataSource;
    }
    this.setState({
      data: sortedData,
    });
  }

  render() {
    return (
      <ApplicationsPresentation
        isAdmin={this.props.isAdmin}
        tokens={this.props.tokens}
        applications={this.state.data}
        localApps={this.props.localApps}
        columns={this.state.columns}
        localColumns={this.state.localColumns}
        onAdd={this.onAdd}
        onAddLocal={this.onAddLocal}
        onEditLocal={this.onEditLocal}
        // App management
        onSelectApplication={this.onSelectApplication}
      />
    );
  }
}

ApplicationsContainer.propTypes = {
  isAdmin: PropTypes.bool,
  localApps: PropTypes.array,
  onAdd: PropTypes.func,
  onAddLocal: PropTypes.func,
  onEdit: PropTypes.func,
  onEditLocal: PropTypes.func,

  // App management
  onSelectApplication: PropTypes.func,
};

ApplicationsContainer.defaultProps = {
  isAdmin: false,
};

export default ApplicationsContainer;
