import React from 'react';
import PropTypes from 'prop-types';

import AppButtonPresentation from "./AppButton.presentation";

class AppButtonContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <AppButtonPresentation
                tokens={this.props.tokens}
                index={this.props.index}
                label={this.props.label}
                icon={this.props.icon}
                compact={this.props.compact}
                active={this.props.active}
                onClick={this.props.onClick}
            />

        );
    }
}

AppButtonContainer.propTypes = {
    index: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.any,
    compact: PropTypes.bool,
    active: PropTypes.bool,
    onClick: PropTypes.func,
}

AppButtonContainer.defaultProps = {
    index: '',
    label: 'AppButton',
    icon: null,
    compact: false,
    active: false,
}

export default AppButtonContainer;
