const TOKEN_KEY = 'token';
const USER_KEY = 'portal-user';

export default class LocalApi {

    static setToken(token) {
        window.localStorage.setItem(TOKEN_KEY, token)
    }

    static getToken() {
        let token = window.localStorage.getItem(TOKEN_KEY)
        return token
    }

    static setUser(user) {
        window.localStorage.setItem(USER_KEY, user)
    }

    static getUser() {
        let user = window.localStorage.getItem(USER_KEY)
        return JSON.parse(user)
    }

    static clear() {
        window.localStorage.removeItem(TOKEN_KEY)
        window.localStorage.removeItem(USER_KEY)
    }

}