import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import "./styles.css";

import {
  Table,
  Container,
  Spacer,
  IconButton,
  Button,
  Text,
  ListItem,
  Dropdown,
} from "squid-ui-kit";

function PaginatedTable({
  itemsPerPage,
  setItemsPerPage,
  dataSource,
  columns,
  getCurrentPage,
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(dataSource.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(dataSource.length / itemsPerPage));
    setCurrentPage(Math.round(itemOffset / itemsPerPage));
  }, [itemOffset, itemsPerPage, dataSource]);

  useEffect(() => {
    setItemOffset(0);
  }, [dataSource]);

  useEffect(() => {
    if (getCurrentPage) {
      getCurrentPage(currentPage);
    }
  }, [currentPage, getCurrentPage]);

  const handlePageClick = (e) => {
    const newOffset = (e.selected * itemsPerPage) % dataSource.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <Table
        width="100%"
        height="100%"
        flex="1"
        dataSource={currentItems}
        columns={columns}
        hoverStyle="active"
        footer={
          <Container
            bodyType="neutral"
            height="fit-content"
            width="100%"
            direction="horizontal"
            horizontalAlign="center"
            verticalAlign="center"
            space="10px"
          >
            <Text>Resultados por página:</Text>
            <Dropdown
              selectedKey="15"
              width="60px"
              placeholder=""
              onChange={e => setItemsPerPage(e)}
            >
              {[10, 15, 30, 50].map((val) => (
                <ListItem
                  key={val}
                  title={`${val}`}
                />
              ))}
              </Dropdown>
            <Spacer />
            <ReactPaginate
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              previousLabel={
                <IconButton
                  size="small"
                  icon="chevron-left"
                />
              }
              nextLabel={
                <IconButton
                  size="small"
                  icon="chevron-right"
                />
              }
              breakClassName="break-classname"
              breakLabel={
                <Button size="small" width="24px" type="link" label="..." />
              }
              pageLabelBuilder={(currPage) => (
                <Button
                  size="small"
                  width="24px"
                  type={currentPage + 1 === currPage ? 'primary' : 'secondary'}
                  label={currPage}
                />
              )}
            />
            <Spacer />
            <Text>
              TOTAL: {dataSource.length} resultados
            </Text>
          </Container>
        }
      />
    </>
  );
}

export default PaginatedTable;
