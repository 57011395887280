import React from "react";
import PropTypes from "prop-types";
import Color from "color";

import {
  Spacer,
  Panel,
  TextField,
  Dropdown,
  ListItem,
  Container,
  Button,
  Text,
  Checkbox,
  IconButton,
  Icon
} from "squid-ui-kit";
import CoreBackendApi from "../../../config/CoreBackendApi";

class WindowFormUser extends React.Component {
  constructor(props) {
    super(props);

    this.petrobrasKeyRef = React.createRef();

    this.state = {
      groups: [],
      petrobrasKey: props.petrobrasKey,
      name: props.name,
      email: props.email,
      id: props.id,
      profile: props.profile,

      // window
      keepOpened: false,
      showSavingLog: false,

      // errors & logs
      errorPetrobrasKey: undefined,
      errorName: undefined,
      errorEmail: undefined,
      errorProfile: undefined,
      logSaving: undefined,
      lastSaveSucceeded: undefined,
    };

    this.resetErrorsAndLogs = () => {
      this.setState({
          errorPetrobrasKey: undefined,
          errorName: undefined,
          errorEmail: undefined,
          errorProfile: undefined,
          logSaving: undefined,
          lastSaveSucceeded: undefined,
          showSavingLog: false,
        });
    };

    this.resetUserData = () => {
      this.setState((state) => {
        return {
          name: "",
          email: "",
          petrobrasKey: "",
          profile: state.groups[0]?.key,
       };
      });
    };

    this.onKeySearch = (e) => {
      // Set initial error & log state
      this.resetErrorsAndLogs();

      // Clear user data
      this.resetUserData();

      // Set modified state
      this.setState(
        {
          petrobrasKey: e.value,
        },
        () => {
          if (this.props.mode === "create") {
            CoreBackendApi.fetchUser(this.state.petrobrasKey, (err) => console.log(err))
              .then((user) => {
                this.setState({
                  name: user.name,
                  email: user.email,
                });
              })
              .catch((error) => {

                let errorPetrobrasKey = `Nenhum usuário corresponde à chave ${e.value.toUpperCase()}`;

                if (e.value === "") {
                  errorPetrobrasKey = "É necessário informar a chave";
                }

                console.log("+++++++++++ [ERROR]", error.message.toUpperCase(), "+++++++++++");

                this.setState({
                  errorPetrobrasKey,
                });
              });
          }
        }
      );
    };
    this.onKeyChange = (e) => {
      this.setState({
        petrobrasKey: e.value,
      });
    };
    this.onUserChange = (e) => {
      this.setState({
        name: e.value,
      });
    };
    this.onEmailChange = (e) => {
      this.setState({
        email: e.value,
      });
    };
    this.onProfileChange = (e) => {
      this.setState({
        profile: e.value,
      });
    };
    this.onSave = async (e) => {

      // User info
      const userKey = this.state.petrobrasKey.toUpperCase();
      const userProfile = this.state.profile;

      const success = () => {
        this.setState((state) => {
          return {
            showSavingLog: true,
            logSaving: (
              <Container bodyType="neutral" direction="vertical" horizontalAlign="center">
                <Text>
                  USUÁRIO ADICIONADO COM SUCESSO!
                </Text>
                <Text>
                  NOME: {state.name}
                </Text>
                <Text>
                  CHAVE: {state.petrobrasKey.toUpperCase()}
                </Text>
              </Container>
            ),
            lastSaveSucceeded: true,
          };
        },
        this.resetUserData);
      };

      const fail = (err) => {

        let logSaving;
        switch(err.message.toUpperCase()) {
          case "USER ALREADY EXISTS.":
          case "USUÁRIO JÁ EXISTE.":
            logSaving = (
              <Container bodyType="neutral" direction="vertical" horizontalAlign="center">
                <Text>
                  JÁ EXISTE UM USUÁRIO ASSOCIADO À CHAVE INFORMADA!
                </Text>
                <Text>
                  NOME: {this.state.name}
                </Text>
                <Text>
                  CHAVE: {userKey}
                </Text>
              </Container>
            )
            break;
          default:
            logSaving = (
              <Text>
                {err.message.toUpperCase()}
              </Text>
            )
            break;
        }

        this.setState({
          showSavingLog: true,
          logSaving,
          lastSaveSucceeded: false,
        },
        this.resetUserData);
      };

      const validateUser = () => {
        if (
          this.props.onSave &&
          this.state.name &&
          this.state.name !== "" &&
          this.state.petrobrasKey &&
          this.state.petrobrasKey !== "" &&
          this.state.profile &&
          this.state.profile !== ""
        ) {
          this.props.onSave({
            petrobrasKey: this.state.petrobrasKey,
            name: this.state.name,
            email: this.state.email,
            id: this.state.id,
            profile: this.state.profile,
          }, success, fail, this.state.keepOpened);
        } else {
          let logMessage;
          if (!this.state.name || this.state.name === "") {
            logMessage = "É NECESSÁRIO INFORMAR O NOME.";
          }
          if (!this.state.petrobrasKey || this.state.petrobrasKey === "") {
            logMessage = "É NECESSÁRIO INFORMAR A CHAVE.";
          }
          if (!this.state.profile || this.state.profile === "") {
            logMessage = "É NECESSÁRIO SELECIONAR O PERFIL.";
          }
  
          this.setState({
            showSavingLog: true,
            logSaving: (
              <Text>
                {logMessage}
              </Text>
            ),
            lastSaveSucceeded: false,
          });
        }
      }

      // Checking validity of petrobrasKey
      return await CoreBackendApi.fetchUser(userKey, (err) => console.log(err.message))
        .then((user) => {
          this.setState({
            name: user.name,
            email: user.email,
            petrobrasKey: userKey,
            profile: userProfile
          }, validateUser);
        })
        .catch((error) => {

          let logMessage = `NENHUM USUÁRIO CORRESPONDE À CHAVE ${userKey}.`;

          if (userKey === "") {
            logMessage = "NENHUMA CHAVE FOI INFORMADA.";
          }

          console.log("+++++++++++ [ERROR]", error.message.toUpperCase(), "+++++++++++");

          this.setState((state) => {
            return {
              errorPetrobrasKey: "",
              showSavingLog: true,
              logSaving: (
                <Text>
                  {logMessage}
                </Text>
              ),
              lastSaveSucceeded: false,
            };
          },
          this.resetUserData);
        });
    };
  }

  componentDidMount() {
    CoreBackendApi.listGroups()
      .then((groupsSquid) => {
        let groups = groupsSquid.map((group) => {
          return <ListItem key={group.id} title={group.name} subtitle="" />;
        });
        let profile = this.state.profile;
        if (this.state.profile && this.state.profile !== "") {
          this.setState(
            {
              profile: "",
              groups,
            },
            () => {
              this.setState({
                profile,
              });
            }
          );
        } else {
          this.setState({
            profile: groups[0]?.key,
            groups,
          });
        }
      });
  }

  render() {
    let footer = (
      <Container
        width="auto"
        bodyType="neutral"
        padding="0px"
        direction="horizontal"
        horizontalAlign="right"
        verticalAlign="center"
      >
        <Checkbox
          invertedColors
          onChange={(e) => { this.setState({ keepOpened: e.checked }); }}
        />
        <Text padding="10px">Adicionar outro usuário</Text>
        <Spacer/>
        <Button
          label={this.props.mode === "create" ? "ADICIONAR" : "EDITAR"}
          type="cta"
          onClick={(e) => {
            this.onSave(e);
            this.petrobrasKeyRef.current.focus();
          }}
        />
      </Container>
    );

    return (
      <div
        style={{
          display: "flex",
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
          backgroundColor: Color(this.props.tokens.ColorBlack).alpha(0.3),
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-start",
          zIndex: 20,
        }}
      >
        <Panel
          label={this.props.title}
          direction="vertical"
          verticalAlign="top"
          horizontalAlign="left"
          bodyType="primary"
          width="500px"
          height="100%"
          footer={footer}
          closeButton={true}
          padding="10px"
          space="10px"
          onClose={this.props.onClose}
        >
          <TextField
            ref={this.petrobrasKeyRef}
            width="100%"
            placeholder="Login (Chave Petrobras)"
            value={this.state.petrobrasKey}
            error={this.state.errorPetrobrasKey}
            searchButton={true}
            readOnly={this.props.mode === "create" ? false : true}
            onChange={this.onKeyChange}
            onSearch={this.onKeySearch}
            onEnter={this.onKeySearch}
          />
          <TextField
            width="100%"
            placeholder="Nome"
            value={this.state.name}
            error={this.state.errorName}
            readOnly={this.props.mode === "create" ? true : false}
            onChange={this.onUserChange}
            disabled={this.props.mode === "create" ? true : false}
          />
          <TextField
            width="100%"
            placeholder="Email"
            readOnly={this.props.mode === "create" ? true : false}
            value={this.state.email}
            error={this.state.errorEmail}
            onChange={this.onEmailChange}
            inputType="email"
            disabled={this.props.mode === "create" ? true : false}
          />
          <Dropdown
            width="100%"
            placeholder="Perfil"
            readOnly={this.props.mode === "create" ? false : true}
            selectedKey={this.state.profile}
            error={this.state.errorProfile}
            onChange={this.onProfileChange}
          >
            {this.state.groups}
          </Dropdown>
          {this.state.showSavingLog && (
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                backgroundColor: this.state.lastSaveSucceeded ? "#166908" : "#a51a0d",
                padding: "2px",
                boxSizing: "border-box",
                flexWrap: true,
                opacity: 1,
                pointerEvents: "auto",
              }}
            >
              <Icon icon={ this.state.lastSaveSucceeded ? "checkmark" : "alert" } size="large" />
              {this.state.logSaving}
              <IconButton icon="close" size="large" onClick={ () => { this.setState({showSavingLog: false}); } } />
            </div>
          )}
        </Panel>
      </div>
    );
  }
}

WindowFormUser.propTypes = {
  title: PropTypes.string,
  mode: PropTypes.oneOf(["create", "edit"]),
  petrobrasKey: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  id: PropTypes.string,
  profile: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

WindowFormUser.defaultProps = {
  title: "Adicionar novo usuário",
  mode: "create",
  petrobrasKey: "",
  name: "",
  email: "",
  id: "",
  profile: "",
};

export default WindowFormUser;
