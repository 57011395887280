import React from 'react';
import PropTypes from 'prop-types';
import CoreBackendApi from '../../config/CoreBackendApi'
import LocalApi from '../../config/LocalApi'

import LoginPresentation from "./Login.presentation";

class LoginContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: '',
        };

        this.onLogin = (user) => {
            if (
                user.user &&
                user.user !== '' &&
                user.password &&
                user.password !== ''
            ) {
                CoreBackendApi.authenticate(user.user, user.password)
                    .then((token) => {
                        LocalApi.setToken(token)
                        if (this.props.onLogged) {
                            CoreBackendApi.fetchMe()
                                .then((me) => {
                                    LocalApi.setUser(JSON.stringify({
                                        avatar: me.avatar,
                                        email: me.email,
                                        groups: me.groups,
                                        id: me.id,
                                        name: me.name,
                                    }))
                                    this.props.onLogged(token)
                                })
                                .catch((error) => {
                                    console.log('+++++++++++ ERROR CALLBACK +++++++++++', error);
                                })
                        }
                    })
                    .catch((error) => {
                        console.log('+++++++++++ ERROR CALLBACK +++++++++++', error);
                        this.setState({
                            error: 'Não foi possível realizar a autenticação. Verifique suas credencias.'
                        });
                    })
            }
        }
    }

    onClear() {
        this.setState({
            error: ''
        });
    }

    render() {
        return (
            <LoginPresentation
                error={this.state.error}
                tokens={this.props.tokens}
                onClear={this.onClear.bind(this)}
                onLogin={this.onLogin}
                showExpirationAlert={this.props.showExpirationAlert}
                onCloseExpirationAlert={this.props.onCloseExpirationAlert}
            />
        );
    }
}

LoginContainer.propTypes = {
    onLogged: PropTypes.func,
    showExpirationAlert: PropTypes.bool,
    onCloseExpirationAlert: PropTypes.func,
}

LoginContainer.defaultProps = {
    showExpirationAlert: false,
}

export default LoginContainer;
