import React from "react";
import PropTypes from "prop-types";
import Color from "color";

import {
  Panel,
  TextField,
  Container,
  Button,
  Text,
  IconButton,
  Icon,
  tokens
} from "squid-ui-kit";
import CoreBackendApi from "../../config/CoreBackendApi";

class AttachUserModal extends React.Component {
  constructor(props) {
    super(props);

    this.petrobrasKeyRef = React.createRef();

    this.state = {
      petrobrasKey: '',
      name: '',
      email: '',
      id: '',

      // window
      showSavingLog: false,

      // errors & logs
      errorPetrobrasKey: undefined,
      errorName: undefined,
      errorEmail: undefined,
      logSaving: undefined,
      lastSaveSucceeded: undefined,
    };

    this.resetErrorsAndLogs = () => {
      this.setState({
          errorPetrobrasKey: undefined,
          errorName: undefined,
          errorEmail: undefined,
          logSaving: undefined,
          lastSaveSucceeded: undefined,
          showSavingLog: false,
        });
    };

    this.resetUserData = () => {
      this.setState((state) => {
        return {
          name: "",
          email: "",
          petrobrasKey: "",
       };
      });
    };

    this.onKeySearch = (e) => {
      // Set initial error & log state
      this.resetErrorsAndLogs();

      // Clear user data
      this.resetUserData();

      // Set modified state
      this.setState(
        {
          petrobrasKey: e.value,
        },
        () => {
          CoreBackendApi.fetchUser(this.state.petrobrasKey, (err) => console.log(err))
            .then((user) => {
              this.setState({
                name: user.name,
                email: user.email,
              });
            })
            .catch((error) => {

              let errorPetrobrasKey = `Nenhum usuário corresponde à chave ${e.value.toUpperCase()}`;

              if (e.value === "") {
                errorPetrobrasKey = "É necessário informar a chave";
              }

              console.log("+++++++++++ [ERROR]", error.message.toUpperCase(), "+++++++++++");

              this.setState({
                errorPetrobrasKey,
              });
            });
        }
      );
    };
    this.onKeyChange = (e) => {
      this.setState({
        petrobrasKey: e.value,
      });
    };
    this.onUserChange = (e) => {
      this.setState({
        name: e.value,
      });
    };
    this.onEmailChange = (e) => {
      this.setState({
        email: e.value,
      });
    };

    this.onSave = async (e) => {
      // User info
      const userKey = this.state.petrobrasKey.toUpperCase();
      const userProfile = this.state.profile;

      const success = () => {
        this.setState((state) => {
          return {
            showSavingLog: true,
            logSaving: (
              <Container bodyType="neutral" direction="vertical" horizontalAlign="center">
                <Text>
                  USUÁRIO ADICIONADO COM SUCESSO!
                </Text>
                <Text>
                  NOME: {state.name}
                </Text>
                <Text>
                  CHAVE: {state.petrobrasKey.toUpperCase()}
                </Text>
              </Container>
            ),
            lastSaveSucceeded: true,
          };
        },
        this.resetUserData);
      };

      const fail = (err) => {
        let logSaving;
        switch(err.response.data.message) {
          case "Usuário já possui autorização no papel.":
            logSaving = (
              <Container bodyType="neutral" direction="vertical" horizontalAlign="center">
                <Text>
                  JÁ EXISTE UM USUÁRIO ASSOCIADO À CHAVE INFORMADA!
                </Text>
                <Text>
                  NOME: {this.state.name}
                </Text>
                <Text>
                  CHAVE: {userKey}
                </Text>
              </Container>
            )
            break;
          default:
            logSaving = (
              <Text>
                {err.message.toUpperCase()}
              </Text>
            )
            break;
        }

        this.setState({
          showSavingLog: true,
          logSaving,
          lastSaveSucceeded: false,
        },
        this.resetUserData);
      };

      const validateUser = () => {
        if (
          this.props.onSave &&
          this.state.name &&
          this.state.name !== "" &&
          this.state.petrobrasKey &&
          this.state.petrobrasKey !== ""
        ) {
          this.props.onSave(this.state.petrobrasKey, success, fail);
        } else {
          let logMessage;
          if (!this.state.name || this.state.name === "") {
            logMessage = "É NECESSÁRIO INFORMAR O NOME.";
          }
          if (!this.state.petrobrasKey || this.state.petrobrasKey === "") {
            logMessage = "É NECESSÁRIO INFORMAR A CHAVE.";
          }
  
          this.setState({
            showSavingLog: true,
            logSaving: (
              <Text>
                {logMessage}
              </Text>
            ),
            lastSaveSucceeded: false,
          });
        }
      }

      // Checking validity of petrobrasKey
      return await CoreBackendApi.fetchUser(userKey, (err) => console.log(err.message))
        .then((user) => {
          this.setState({
            name: user.name,
            email: user.email,
            petrobrasKey: userKey,
            profile: userProfile
          }, validateUser);
        })
        .catch((error) => {

          let logMessage = `NENHUM USUÁRIO CORRESPONDE À CHAVE ${userKey}.`;

          if (userKey === "") {
            logMessage = "NENHUMA CHAVE FOI INFORMADA.";
          }

          console.log("+++++++++++ [ERROR]", error.message.toUpperCase(), "+++++++++++");

          this.setState((state) => {
            return {
              errorPetrobrasKey: "",
              showSavingLog: true,
              logSaving: (
                <Text>
                  {logMessage}
                </Text>
              ),
              lastSaveSucceeded: false,
            };
          },
          this.resetUserData);
        });
    };
  }

  render() {
    let footer = (
      <Container
        width="auto"
        bodyType="neutral"
        padding="0px"
        direction="horizontal"
        horizontalAlign="right"
        verticalAlign="center"
      >
        <Button
          label={"ADICIONAR"}
          type="cta"
          size='small'
          onClick={(e) => {
            this.onSave(e);
            this.petrobrasKeyRef.current.focus();
          }}
        />
      </Container>
    );

    return (
      <div
        style={{
          display: "flex",
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
          backgroundColor: Color(tokens.ColorBlack).alpha(0.3),
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-start",
          zIndex: 20,
        }}
      >
        <Panel
          label={this.props.title}
          direction="vertical"
          verticalAlign="top"
          horizontalAlign="left"
          bodyType="primary"
          width="500px"
          height="100%"
          footer={footer}
          closeButton={true}
          padding="10px"
          space="10px"
          onClose={this.props.onClose}
        >
          <TextField
            ref={this.petrobrasKeyRef}
            name="portal-settings-attach-modal"
            width="100%"
            placeholder="Login (Chave Petrobras)"
            value={this.state.petrobrasKey}
            error={this.state.errorPetrobrasKey}
            searchButton={true}
            readOnly={this.props.mode === "create" ? false : true}
            onChange={this.onKeyChange}
            onSearch={this.onKeySearch}
            onEnter={this.onKeySearch}
            onFocus={() => {console.log("Petrobras key was focused.");}}
          />
          <TextField
            width="100%"
            placeholder="Nome"
            value={this.state.name}
            error={this.state.errorName}
            readOnly={this.props.mode === "create" ? true : false}
            onChange={this.onUserChange}
            disabled={this.props.mode === "create" ? true : false}
          />
          <TextField
            width="100%"
            placeholder="Email"
            readOnly={this.props.mode === "create" ? true : false}
            value={this.state.email}
            error={this.state.errorEmail}
            onChange={this.onEmailChange}
            inputType="email"
            disabled={this.props.mode === "create" ? true : false}
          />

          {this.state.showSavingLog && (
            <Container 
              bodyType={this.state.lastSaveSucceeded ? "success" : "danger"} 
              width='100%' 
              direction="horizontal" 
              padding='5px' 
              space='10px' 
              horizontalAlign="center" 
              verticalAlign="center"
            >
                <Container 
                  horizontalAlign="center" 
                  verticalAlign="center"
                  width='40px'
                  direction="vertical"
                  bodyType="neutral"
                > 
                  <Icon icon={ this.state.lastSaveSucceeded ? "checkmark" : "alert" } size="large" />
                </Container>
                <Container 
                  horizontalAlign="center" 
                  verticalAlign="center"
                  direction="vertical"
                  flex='1'
                  bodyType="neutral"
                > 
                  {this.state.logSaving}
                </Container>
                <Container 
                  horizontalAlign="center" 
                  verticalAlign="center"
                  direction="vertical"
                  width='40px'
                  bodyType="neutral"
                > 
                  <IconButton icon="close" size="large" onClick={ () => { this.setState({showSavingLog: false}); } } />
                </Container>
            </Container>
          )}
        </Panel>
      </div>
    );
  }
}

AttachUserModal.propTypes = {
  title: PropTypes.string,
  mode: PropTypes.oneOf(["create", "edit"]),
  petrobrasKey: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  id: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  loading: PropTypes.bool,
};

AttachUserModal.defaultProps = {
  title: "Adicionar novo Usuário",
  mode: "create",
  petrobrasKey: "",
  name: "",
  email: "",
  id: "",
};

export default AttachUserModal;
