import React from "react";
import PropTypes from "prop-types";
import eraserIcon from "../../assets/eraser-icon.svg";
import refreshIcon from "../../assets/refresh-icon.svg";
import alertIcon from "../../assets/alert-icon.svg";

import {
  Container,
  Spacer,
  Panel,
  Text,
  Icon,
  Title,
  TableCell,
  Checkbox,
  Tooltip,
} from "squid-ui-kit";

class HealthCheckPresentation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Container
        width="100%"
        height="100%"
        bodyType="neutral"
        flex="1"
        padding="15px"
        direction="horizontal"
      >
        <Container flex="1" height="100%" minWidth="250px">
          <Panel
            label=""
            titleBarContent={
              <Container
                width="100%"
                height="100%"
                direction="horizontal"
                verticalAlign="center"
                bodyType="neutral"
              >
                <Title>APLICAÇÕES</Title>
                <Spacer flex="1" direction="horizontal" />
                <Container
                  direction="horizontal"
                  bodyType="neutral"
                  onClick={() => this.props.toggleAll(false)}
                  verticalAlign="center"
                  width="fit-content"
                >
                  <img src={eraserIcon} alt={"eraser"} />
                  <Spacer size="5px" direction="horizontal" />
                  <Title>LIMPAR SELEÇÃO</Title>
                  <Spacer size="20px" direction="horizontal" />
                </Container>
              </Container>
            }
            width="100%"
            height="100%"
            progress={this.props.loadingApps ? -1 : undefined}
            progressLabel={
              this.props.loadingApps ? "Carregando aplicações..." : undefined
            }
          >
            {this.props.applicationsToShow?.length !== 0 && (
              <Container width="100%" height="28px" bodyType="neutral">
                <TableCell
                  width="100%"
                  height="100%"
                  bodyType="neutral"
                  padding="0px 4px 0px 8px"
                  verticalAlign="center"
                >
                  <Checkbox
                    checked={this.props.checkIfAllChecked()}
                    invertedColors={true}
                    onChange={(e) => {
                      this.props.toggleAll(e?.checked);
                    }}
                  />
                  <Spacer size="8px" direction="horizontal" />
                  <Text size="large" flex="1">
                    Todas as aplicações
                  </Text>
                </TableCell>
              </Container>
            )}
            {this.props.applicationsToShow?.map((app) => {
              const appStatusKey = !app?.slug ? "SLUGLESS" : app.status;
              const errorMessage =
                this.props.getCheckboxErrorMessage(app)[appStatusKey];
              return (
                <Container
                  width="100%"
                  height="28px"
                  bodyType="neutral"
                  key={"app" + app?.name}
                >
                  <TableCell
                    width="100%"
                    height="100%"
                    bodyType="neutral"
                    padding="0px 4px 0px 8px"
                  >
                    <Container
                      direction="horizontal"
                      verticalAlign="center"
                      bodyType="neutral"
                      width="100%"
                    >
                      <Checkbox
                        checked={app?.checked}
                        onChange={() => this.props.toggleCheckApp(app?.slug)}
                        invertedColors
                        disabled={this.props.isNotCheckable(app)}
                      />
                      <Spacer size="8px" direction="horizontal" />
                      <Text flex="1" size="large">
                        {app.name}
                      </Text>
                      {Boolean(errorMessage) && (
                        <Tooltip title={errorMessage} placement="topLeft">
                          <Icon icon="alert" color={app.status === "NOT_REGISTERED" ? "red" : "orange"} />
                        </Tooltip>
                      )}
                    </Container>
                  </TableCell>
                </Container>
              );
            })}
          </Panel>
        </Container>
        <Spacer size="20px" direction="horizontal" />
        <Container flex="3" height="100%">
          <Panel
            label=""
            titleBarContent={
              <Container
                width="100%"
                height="100%"
                direction="horizontal"
                verticalAlign="center"
                bodyType="neutral"
              >
                <Title>STATUS DAS APLICAÇÕES</Title>
                <Spacer flex="1" direction="horizontal" />
                <Container
                  direction="horizontal"
                  bodyType="neutral"
                  onClick={this.props.refreshGraph}
                  verticalAlign="center"
                  width="fit-content"
                >
                  <img src={refreshIcon} alt={"refresh"} />
                  <Spacer size="5px" direction="horizontal" />
                  <Title>ATUALIZAR</Title>
                  <Spacer size="20px" direction="horizontal" />
                </Container>
              </Container>
            }
            width="100%"
            height="100%"
          >
            <Container
              width="100%"
              flex="1"
              scroll="vertical"
              bodyType="neutral"
            >
              {this.props.applicationsToShow?.filter((app) => app.checked)
                .length === 0 ? (
                <Container
                  width="100%"
                  verticalAlign="center"
                  horizontalAlign="center"
                  bodyType="neutral"
                  padding="16px"
                  space="6px"
                  height="100%"
                >
                  <img src={alertIcon} alt={"warning"} />
                  <div />
                  <Title level={3}>VOCÊ PRECISA SELECIONAR UMA APLICAÇÃO</Title>
                  <Text>Selecione na lista ao lado para exibir o grafo</Text>
                </Container>
              ) : this?.props?.graphUrl ? (
                <Container
                  width="100%"
                  height="100%"
                  bodyType="neutral"
                  progress={this.props.loadingGraph ? -1 : undefined}
                  progressBodyTypeAlpha={0.05}
                  progressBodyType="primary"
                  progressLabel={"Healthchecking..."}
                >
                  <iframe
                    key={`updatedAt${this.props.timeStamp}`}
                    src={this.props.graphUrl}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    title="Grafo de Healthcheck"
                    onLoad={() => {
                      this.props.setLoadingGraph(false);
                    }}
                  />
                </Container>
              ) : (
                !this.props.loadingGraph && (
                  <Container
                    width="100%"
                    height="100%"
                    verticalAlign="center"
                    horizontalAlign="center"
                    bodyType="neutral"
                  >
                    <Icon icon="alert" />
                    <Spacer size="5px" direction="horizontal" />
                    <Text size="large">
                      Status das aplicações não encontrado
                    </Text>
                  </Container>
                )
              )}
            </Container>
          </Panel>
        </Container>
      </Container>
    );
  }
}

HealthCheckPresentation.propTypes = {
  applicationsToShow: PropTypes.arrayOf(PropTypes.object),
  toggleCheckApp: PropTypes.func,
  graphUrl: PropTypes.string,
  updateGraph: PropTypes.func,
  refreshGraph: PropTypes.func,
  timeStamp: PropTypes.number,
  setLoadingGraph: PropTypes.func,
  loadingGraph: PropTypes.bool,
  loadingApps: PropTypes.bool,
  isNotCheckable: PropTypes.func,
  getCheckboxErrorMessage: PropTypes.func,
  toggleAll: PropTypes.func,
  checkIfAllChecked: PropTypes.func,
};

HealthCheckPresentation.defaultProps = {};

export default HealthCheckPresentation;
