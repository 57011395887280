import React from "react";
import HealthCheckPresentation from "./HealthCheck.presentation";
import LocalApi from "../../config/LocalApi";
import { squidUrl } from "../../config/Environment";
import ConfigApi from "../../config/ConfigApi";

class HealthCheckContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      applicationsToShow: null,
      loadingApps: false,
      graphUrl: "",
      graphStyles: {
        // set custom graph parameters here
        // upColor: "#00FF00",
        // downColor: "#FF0000",
        // unknownColor: "#FFFF00",
        // notFoundColor: "#FFFFFF,
        tooltipFontColor: "#213B62",
        tooltipBackgroundColor: "#FFFFFF",
        font: "Roboto",
        fontColor: "#FFFFFF",
        neutralColor: "#FFFFFF",
      },
      timeStamp: Date.now(),
      loadingGraph: true,
    };

    this.isNotCheckable = (app) => {
      return Boolean(app.status === "NOT_REGISTERED")
    }

    this.toggleCheckApp = (slug) => {
      let toggledAppIndex = this.state.applicationsToShow?.findIndex(
        (app) => app.slug === slug
      );
      if (toggledAppIndex < 0) return;
      let applicationsToShow = this.state.applicationsToShow;
      applicationsToShow[toggledAppIndex].checked =
        !applicationsToShow[toggledAppIndex].checked;
      this.setState({ applicationsToShow });
      this.updateGraph();
    };

    this.toggleAll = (checked) => {
      let applicationsToShow = this.state.applicationsToShow;
      applicationsToShow.forEach((app) => {
        if (this.isNotCheckable(app)) return;
        app.checked = checked;
      });
      this.setState({ applicationsToShow });
      this.updateGraph();
    };

    this.checkIfAllChecked = () => {
      return this.state.applicationsToShow?.every((app) => {
        // check if is checked or has error
        return app.checked || this.isNotCheckable(app);
      });
    };

    this.parseStylesToUrlParams = () => {
      return Object.entries(this.state.graphStyles)
        .map(
          ([key, value]) =>
            `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("");
    };

    this.setLoadingApps = (loading) => {
      this.setState({ loadingApps: loading });
    };

    this.getApps = () => {
      ConfigApi.getHealthCheckApps((data) => {
        const apps = data.map((app) => {
          app.checked = !this.isNotCheckable(app);
          return app;
        });
        this.setState({ applicationsToShow: apps });
        this.updateGraph();
      }, this.setLoadingApps);
    };

    this.mountGraphUrl = () => {
      const baseUrl = `${squidUrl}/api/healthcheck/dependency-graph.html?token=${encodeURIComponent(
        LocalApi.getToken()
      )}`;

      let appsList = ""; // default filter to show all apps
      if (!this.checkIfAllChecked()) {
        const checkedApps = this.state.applicationsToShow?.filter(
          (app) => app.checked
        );
        appsList =
          "&applications=" +
          checkedApps
            ?.map((app) => encodeURIComponent(app.slug))
            ?.join("&applications=");
      }

      const styles = this.parseStylesToUrlParams();

      return `${baseUrl}${appsList}${styles}`;
    };

    this.updateGraph = () => {
      this.setLoadingGraph(true);
      this.setState({ graphUrl: this.mountGraphUrl() });
    };

    this.refreshGraph = () => {
      this.setLoadingGraph(true);
      this.setState({ timeStamp: Date.now() });
    };

    this.setLoadingGraph = (loading) => {
      this.setState({ loadingGraph: loading });
    };

    this.getCheckboxErrorMessage = (app) => {
      return {
        SLUGLESS:
          "Esta aplicação não possui um indicador único (slug). Portanto, não pode ser mapeada pelo Healthcheck.",
        NOT_REGISTERED:
          "Esta aplicação não está registrada no Healthcheck. Caso deseje monitorá-la, entre em contato com o administrador para adicioná-la no serviço de Healthcheck.",
        NOT_FOUND: `Esta aplicação não foi encontrada no portal. Entre em contato com o administrador para verificar se existe alguma aplicação com o slug ${app?.slug} no portal`,
      };
    };
  }

  componentDidMount() {
    this.getApps();
  }

  render() {
    return (
      <HealthCheckPresentation
        applicationsToShow={this.state.applicationsToShow}
        toggleCheckApp={this.toggleCheckApp}
        updateGraph={this.updateGraph}
        refreshGraph={this.refreshGraph}
        graphUrl={this.state.graphUrl}
        timeStamp={this.state.timeStamp}
        setLoadingGraph={this.setLoadingGraph}
        loadingGraph={this.state.loadingGraph}
        loadingApps={this.state.loadingApps}
        isNotCheckable={this.isNotCheckable}
        getCheckboxErrorMessage={this.getCheckboxErrorMessage}
        toggleAll={this.toggleAll}
        checkIfAllChecked={this.checkIfAllChecked}
      />
    );
  }
}

HealthCheckContainer.defaultProps = {};

export default HealthCheckContainer;
