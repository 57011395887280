import React from "react";
import PropTypes from "prop-types";

import LogoLccv from "../../assets/logo-lccv.svg";
import LogoPetrobras from "../../assets/logo-petrobras.svg";

import {
  Alert,
  Container,
  Panel,
  Spacer,
  TextField,
  Button,
  tokens,
} from "squid-ui-kit";

class LoginPresentation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showHelp: false,
      user: "",
      password: "",
    };

    this.onUserChange = (e) => {
      this.setState({
        user: e.value,
      });
    };
    this.onPasswordChange = (e) => {
      this.setState({
        password: e.value,
      });
    };
    this.onLogin = (e) => {
      if (this.props.onLogin) {
        this.props.onLogin({
          user: this.state.user,
          password: this.state.password,
        });
      }
    };
    this.onHelp = (e) => {
      this.setState({
        showHelp: true,
      });
    };
  }

  render() {
    let content;
    if (this.state.showHelp === false) {
      content = (
        <Panel
          label="Acesso"
          width="400px"
          height="220px"
          direction="vertical"
          verticalAlign="top"
          bodyType="primary"
          padding="10px"
          shadow={true}
          footer={
            <Container
              width="auto"
              bodyType="neutral"
              padding="0px"
              direction="horizontal"
              horizontalAlign="right"
            >
              <Button
                label="Preciso de ajuda"
                type="link"
                onClick={this.onHelp}
              />
              <Spacer flex="1" />
              <Button label="ENTRAR" type="primary" onClick={this.onLogin} />
            </Container>
          }
        >
          <TextField
            width="100%"
            label="Usuário"
            name="portal-user-inp"
            placeholder="letras e números"
            invertedColors={false}
            value={this.state.user}
            onChange={this.onUserChange}
            onEnter={this.onLogin}
          />
          <Spacer direction="vertical" size="18px" />
          <TextField
            width="100%"
            label="Senha"
            name="portal-pass-inp"
            placeholder="*****"
            inputType="password"
            invertedColors={false}
            value={this.state.password}
            onChange={this.onPasswordChange}
            onEnter={this.onLogin}
          />
        </Panel>
      );
    } else {
      content = (
        <Panel
          label="Suporte ao sistema"
          width="500px"
          height="200px"
          direction="vertical"
          verticalAlign="top"
          horizontalAlign="left"
          bodyType="content"
          margin="0px"
          padding={tokens.PrimaryPadding}
          shadow={true}
          closeButton={true}
          onClose={() => {
            this.setState({
              showHelp: false,
            });
          }}
        >
          <div
            style={{
              fontFamily: this.props.tokens.FontControlDefaultFamily,
              fontWeight: this.props.tokens.FontBodySecondaryWeight,
              fontSize: this.props.tokens.FontControlDefaultSize,
              lineHeight: this.props.tokens.LineHeightBodyAction,
            }}
          >
            <p>Olá, tudo bem?</p>
            <br />
            <p>
              Esse é o seu canal de suporte. Para dúvidas, problemas ou pedidos
              de acesso, pedimos que seja enviado um email para o nosso canal no
              endereço:
            </p>
            <p style={{ fontWeight: 900 }}>
              cc-suporte-navalsubweb@petrobras.com.br
            </p>
            <br />
            <p>Por lá, vamos agilizar o atendimento de sua solicitação.</p>
            <br />
            <p>Obrigado.</p>
          </div>
        </Panel>
      );
    }
    let authAlert;
    if (this.props.error && this.props.error !== "") {
      authAlert = (
        <Alert
          hasBorder={true}
          title="Atenção: Erro ao tentar se autenticar"
          message={this.props.error}
          onClose={this.props.onClear}
          bodyType="content"
          padding={tokens.PrimaryPadding}
        />
      );
    }

    let expirationAlert;
    if (this.props.showExpirationAlert && this.props.showExpirationAlert === true) {
      expirationAlert = (
        <Alert
          hasBorder={true}
          title="Sessão expirada"
          closeable="true"
          message="Sua sessão expirou. Feche esta janela e faça login novamente."
          onClose={this.props.onCloseExpirationAlert}
          bodyType="content"
          padding={tokens.PrimaryPadding} // "10px"
        />
      );
    }

    return (
      <div
        className="App"
        style={{
          position: "absolute",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
          // backgroundColor: this.props.tokens.ColorPrimary,
        }}
        onClick={this.props.onCloseExpirationAlert}
      >
        <Container
          width="100%"
          height="100%"
          direction="horizontal"
          verticalAlign="top"
          bodyType="neutral"
          margin="0px"
          padding="0px"
        >
          <Container
            flex="3"
            height="100%"
            direction="vertical"
            horizontalAlign="left"
            verticalAlign="center"
            bodyType="neutral"
            margin="0px 0px 0px 0px"
            // margin="0px"
            padding={tokens.PrimaryPadding}
          >
            {/* <Spacer
              direction="vertical"
              size={
                window.SQUID_PORTAL_ID === "portal_navalweb" ? "22%" : "32%"
              }
            /> */}
            <Spacer direction="vertical" size="25%" />
            <Container
              // width={
              //   window.SQUID_PORTAL_ID === "portal_navalweb" ? "500px" : "100%"
              // }
              width="500px"
              height="auto"
              direction="vertical"
              verticalAlign="center"
              horizontalAlign="center"
              bodyType="neutral"
              // margin="0px"
              margin="0px 0px 0px 80px"
              padding="0px"
            >
              <Container
                width="auto"
                height="auto"
                direction="horizontal"
                verticalAlign="center"
                horizontalAlign="center"
                bodyType="neutral"
                margin="0px"
                padding="0px"
              >
                <img
                  alt=""
                  style={{
                    width: "auto",
                    height: "auto",
                    // minHeight: "85px",
                    // filter: `drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.3))`,
                  }}
                  src={`${window.SQUID_PORTAL_ID}.svg`}
                />
              </Container>
              <Spacer direction="vertical" size="50px" />
              {content}
            </Container>
            {/* <Spacer direction="vertical" size="20px" /> */}
            <Spacer direction="vertical" flex="1" />
            {/* <Spacer direction="vertical" flex="4" /> */}
            {/* <Container
              flex="4"
              height="300px"
              direction="horizontal"
              verticalAlign="center"
              bodyType="neutral"
              margin="0px"
              padding="0px"
            >
              <div
                style={{
                  backgroundColor: "white",
                  filter: `drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.3))`,
                }}
              >
                {help}
              </div>
            </Container> */}
            <Container
              // width={
              //   window.SQUID_PORTAL_ID === "portal_navalweb" ? "500px" : "100%"
              // }
              width="500px"
              height="52px"
              direction="horizontal"
              verticalAlign="center"
              horizontalAlign="center"
              bodyType="neutral"
              // margin="0px"
              margin="0px 0px 0px 80px"
              padding="0px"
            >
              <img
                alt=""
                style={{
                  filter: `drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.3))`,
                }}
                src={LogoPetrobras}
              />
              <Spacer size="20px" />
              <img
                alt=""
                style={{
                  filter: `drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.3))`,
                }}
                src={LogoLccv}
              />
            </Container>
            {/* <Spacer direction="vertical" flex="4" /> */}
            <Spacer direction="vertical" size="20px" />
          </Container>
          {/* <Spacer size="100px" /> */}
          {/* <Divider direction="vertical" /> */}
          {/* <Container
            flex="4"
            height="100%"
            direction="horizontal"
            verticalAlign="center"
            bodyType="neutral"
            margin="0px"
            padding="0px"
          >
            <div
              style={{
                backgroundColor: "white",
                filter: `drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.3))`,
              }}
            >
              {help}
            </div>
          </Container> */}
        </Container>
        {authAlert}
        {expirationAlert}
      </div>
    );
  }
}

LoginPresentation.propTypes = {
  error: PropTypes.string,
  apps: PropTypes.arrayOf(PropTypes.object),
  onClickApp: PropTypes.func,
  onClear: PropTypes.func,
  onLogin: PropTypes.func,
  showExpirationAlert: PropTypes.bool,
  onCloseExpirationAlert: PropTypes.func,
};

LoginPresentation.defaultProps = {
  error: "",
  apps: [],
  showExpirationAlert: false,
};

export default LoginPresentation;
